import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import {
    DEFAULT_STYLING,
    BARCHART_DIRECTION,
    BARCHART_LABEL_POSITION,
    calculateDomain,
    calculateLabelPosition,
    calculateTooltipPosition,
    calculateXYCoordinatesBarChart,
} from '../data';

/**
 * @typedef Data
 * @prop {string} name
 * @prop {string} value
 * @prop {React.CSSProperties['color']} color
 */

/**
 * @typedef Styling
 * @prop {React.CSSProperties['width']} [barWidth]
 * @prop {React.SVGAttributes['stroke']} [barStroke]
 * @prop {React.CSSProperties['fontWeight']} [fontWeight]
 * @prop {React.CSSProperties['fontSize']} [barFontSize]
 * @prop {React.CSSProperties['color']} [defaultColor]
 * @prop {React.CSSProperties['color']} [barLabelColor]
 * @prop {React.CSSProperties['color']} [barNamesColor]
 * @prop {React.CSSProperties['width']} [barNamesWidth]
 * @prop {React.CSSProperties['color']} [barStrokeColor]
 * @prop {React.CSSProperties['borderRadius']} [barBorderRadius]
 * @prop {React.CSSProperties['backgroundColor']} [backgroundColor]
 * @prop {React.CSSProperties['fontSize']} [barNamesFontSize]
 * @prop {number} [barLabelMainAxisOffset]
 * @prop {number} [barNamesMainAxisOffset]
 * @prop {number} [barLabelSecondaryAxisOffset]
 * @prop {number} [barNamesSecondaryAxisOffset]
 * @prop {React.CSSProperties['width']} [tooltipWidth]
 * @prop {React.CSSProperties['height']} [tooltipHeight]
 * @prop {React.SVGAttributes['stroke']} [tooltipStroke]
 * @prop {number} [tooltipOffsetX]
 * @prop {number} [tooltipOffsetY]
 * @prop {React.CSSProperties['color']} [tooltipFontColor]
 * @prop {React.SVGAttributes['fill']} [tooltipBackground]
 * @prop {React.CSSProperties['color']} [tooltipStrokeColor]
 * @prop {React.CSSProperties['borderRadius']} [tooltipBorderRadius]
 */

/**
 * @typedef Props
 * @prop {Array<Data>} data
 * @prop {React.CSSProperties['width']} [width]
 * @prop {React.CSSProperties['height']} [height]
 * @prop {number} [domain]
 * @prop {Styling} [styling]
 * @prop {boolean} [withNames]
 * @prop {string} [direction]
 * @prop {React.CSSProperties['translateX']} [translateX]
 * @prop {React.CSSProperties['translateY']} [translateY]
 * @prop {React.CSSProperties['width']} [marginWidth]
 * @prop {React.CSSProperties['height']} [marginHeight]
 * @prop {string} [labelPosition]
 * @prop {(value: string) => string} [labelFormatter]
 * @prop {number} [tooltipShowSize]
 * @prop {(data: Data) => string} [tooltipFormatter]
 * @prop {boolean} [alwaysOnTooltips]
 */

/**
 * @type {React.FC<Props>}
 * @return {JSX.Element}
 */
export const BarChart = ({
    data,
    width,
    height,
    domain,
    styling,
    withNames,
    direction,
    translateX,
    translateY,
    marginWidth,
    marginHeight,
    labelPosition,
    labelFormatter,
    tooltipShowSize,
    tooltipFormatter,
    alwaysOnTooltips,
}) => {
    const barSvgRef = useRef(null);
    const barNamesRef = useRef(null);
    const {
        barWidth,
        barStroke,
        fontWeight,
        barFontSize,
        defaultColor,
        barLabelColor,
        barNamesColor,
        barNamesWidth,
        barStrokeColor,
        barBorderRadius,
        backgroundColor,
        barNamesFontSize,
        barLabelMainAxisOffset,
        barNamesMainAxisOffset,
        barLabelSecondaryAxisOffset,
        barNamesSecondaryAxisOffset,
        tooltipWidth,
        tooltipHeight,
        tooltipStroke,
        tooltipOffsetX,
        tooltipOffsetY,
        tooltipFontColor,
        tooltipBackground,
        tooltipStrokeColor,
        tooltipBorderRadius,
    } = { ...DEFAULT_STYLING, ...styling };

    const calculateLabelValues = value => {
        return labelFormatter ? labelFormatter(value) : value;
    };

    const isBarTooSmall = value => {
        return value < tooltipShowSize;
    };

    const drawVertical = () => {
        let group = d3.select(barSvgRef.current);
        let nameGroup = d3.select(barNamesRef.current);

        let x = d3
            .scaleBand()
            .range([0, width])
            .domain(data.map(d => d.name))
            .padding(0.2);
        let y = d3
            .scaleLinear()
            .domain([0, calculateDomain({ data, domain })])
            .range([height, 0]);

        if (backgroundColor) {
            group
                .selectAll('.classedBackground')
                .data(data)
                .join('rect')
                .classed('classedBackground', true)
                .attr('fill', backgroundColor)
                .attr('x', d => x(d.name))
                .attr('y', d => 0)
                .attr('width', barWidth || x.bandwidth())
                .attr('height', height)
                .attr('data-testid', 'barchart-bg');
        }

        /* Draw bars and assign tooltip to each bar */
        group
            .selectAll('.bars-vertical')
            .data(data)
            .join('rect')
            .classed('bars-vertical', true)
            .attr('data-testid', `barchart-${BARCHART_DIRECTION.VERTICAL}`)
            .attr('fill', d => (d.hasOwnProperty('color') ? d.color : defaultColor))
            .attr('x', d => x(d.name))
            .attr('y', d => y(d.value))
            .attr('width', barWidth || x.bandwidth())
            .attr('height', d => height - y(d.value))
            .attr('rx', barBorderRadius)
            .attr('ry', barBorderRadius)
            .attr('stroke-width', barStroke)
            .attr('stroke', barStrokeColor)
            .on('mouseover', function (d, datum) {
                if (isBarTooSmall(datum.value) || alwaysOnTooltips) {
                    d3.select(barSvgRef.current).select('.tooltip').style('display', null);
                }
            })
            .on('mouseout', function () {
                d3.select(barSvgRef.current).select('.tooltip').style('display', 'none');
            })
            .on('mousemove', function (d, datum) {
                const { xPosition, yPosition } = calculateXYCoordinatesBarChart({
                    xCoordinate: d3.pointer(d)[0],
                    yCoordinate: d3.pointer(d)[1],
                    tooltipOffsetX,
                    tooltipHeight,
                    tooltipOffsetY,
                });

                calculateTooltipPosition({ xPosition, yPosition, dataObject: datum, barSvgRef, tooltipFormatter });
            });

        /* Draw labels */
        group
            .selectAll('.barChartLabels')
            .data(data)
            .join('text')
            .classed('barChartLabels', true)
            .attr('x', d => x(d.name) + (barWidth || x.bandwidth()) / 3 + barLabelSecondaryAxisOffset)
            .attr('y', d =>
                calculateLabelPosition({
                    bound: d.value,
                    labelPosition,
                    direction,
                    height,
                    width,
                    barLabelMainAxisOffset,
                    data,
                    domain,
                })
            )
            .attr('dy', '.75em')
            .attr('data-testid', 'barchart-labels')
            .style('fill', d => d?.textColor || barLabelColor)
            .style('font-size', barFontSize)
            .style('font-weight', fontWeight)
            .text(d => calculateLabelValues(d.value));

        /* Draw bar names */
        if (withNames) {
            nameGroup.selectAll('*').remove();
            nameGroup
                .selectAll('.barChartNames')
                .data(data)
                .join('foreignObject')
                .classed('barChartNames', true)
                .attr('width', barWidth || x.bandwidth())
                .attr('height', 70)
                .attr('x', d => x(d.name) + barNamesSecondaryAxisOffset)
                .attr('y', height + barNamesMainAxisOffset)
                .attr('dy', '.75em')
                .append('xhtml:div')
                .attr('data-testid', 'barchart-name')
                .style('word-break', 'break-all')
                .style('text-align', 'center')
                .style('font-size', barNamesFontSize + 'px')
                .style('font-weight', fontWeight)
                .style('color', barNamesColor)
                .html(d => d.name);
        }
    };

    const drawHorizontal = () => {
        let group = d3.select(barSvgRef.current);
        let nameGroup = d3.select(barNamesRef.current);

        let x = d3
            .scaleLinear()
            .domain([0, calculateDomain({ data, domain })])
            .range([0, width]);
        let y = d3
            .scaleBand()
            .range([height, 0])
            .domain(data.map(d => d.name))
            .padding(0.2);

        if (backgroundColor) {
            group
                .selectAll('.classedBackground')
                .data(data)
                .join('rect')
                .classed('classedBackground', true)
                .attr('fill', backgroundColor)
                .attr('x', 0)
                .attr('y', d => y(d.name))
                .attr('width', width)
                .attr('height', barWidth || y.bandwidth());
        }

        /* Draw bars and assign tooltip to each bar */
        group
            .selectAll('.bars')
            .data(data)
            .join('rect')
            .classed('bars', true)
            .attr('fill', d => (d.hasOwnProperty('color') ? d.color : defaultColor))
            .attr('x', 0)
            .attr('y', d => y(d.name))
            .attr('width', d => x(d.value))
            .attr('height', barWidth || y.bandwidth())
            .attr('rx', barBorderRadius)
            .attr('ry', barBorderRadius)
            .attr('stroke-width', barStroke)
            .attr('stroke', barStrokeColor)
            .attr('data-testid', `barchart-${BARCHART_DIRECTION.HORIZONTAL}`)
            .on('mouseover', function (d, datum) {
                if (isBarTooSmall(datum.value) || alwaysOnTooltips) {
                    d3.select(barSvgRef.current).select('.tooltip').style('display', null);
                }
            })
            .on('mouseout', function () {
                d3.select(barSvgRef.current).select('.tooltip').style('display', 'none');
            })
            .on('mousemove', function (d, datum) {
                const { xPosition, yPosition } = calculateXYCoordinatesBarChart({
                    xCoordinate: d3.pointer(d)[0],
                    yCoordinate: d3.pointer(d)[1],
                    tooltipOffsetX,
                    tooltipHeight,
                    tooltipOffsetY,
                });

                calculateTooltipPosition({ xPosition, yPosition, dataObject: datum, barSvgRef, tooltipFormatter });
            });

        /* Draw labels for each bar */
        group
            .selectAll('.barChartLabels')
            .data(data)
            .join('text')
            .classed('barChartLabels', true)
            .attr('x', d =>
                calculateLabelPosition({
                    bound: d.value,
                    labelPosition,
                    direction,
                    height,
                    width,
                    barLabelMainAxisOffset,
                    data,
                    domain,
                })
            )
            .attr('y', d => y(d.name) + (barWidth || y.bandwidth()) / 3 + barLabelSecondaryAxisOffset)
            .attr('dy', '.75em')
            .attr('text-anchor', 'middle')
            .attr('data-testid', 'barchart-labels')
            .style('fill', d => d?.textColor || barLabelColor)
            .style('font-size', barFontSize)
            .style('font-weight', fontWeight)
            .text(d => calculateLabelValues(d.value));

        /* Draw bar names */
        if (withNames) {
            nameGroup.selectAll('*').remove();
            nameGroup
                .selectAll('.barChartNames')
                .data(data)
                .join('foreignObject')
                .classed('barChartNames', true)
                .merge(nameGroup)
                .attr('width', barNamesWidth)
                .attr('height', barWidth || y.bandwidth())
                .attr('x', barNamesMainAxisOffset)
                .attr('y', d => y(d.name) + y.bandwidth() / 2 + barNamesSecondaryAxisOffset)
                .attr('dy', '.75em')
                .attr('data-testid', 'barchart-name-wrapper')
                .append('xhtml:div')
                .attr('data-testid', 'barchart-name')
                .style('word-break', 'break-all')
                .style('font-size', barNamesFontSize + 'px')
                .style('font-weight', fontWeight)
                .style('color', barNamesColor)
                .html(d => d.name);
        }
    };

    useEffect(() => {
        switch (direction) {
            case BARCHART_DIRECTION.HORIZONTAL:
                drawHorizontal();
                break;
            default:
                drawVertical();
        }
    }, [data]);

    useEffect(() => {
        let group = d3.select(barSvgRef.current);
        group.style('transform', `translate(${translateX}px, ${translateY}px)`);
    }, [translateX, translateY]);

    /* Add the tooltip */
    useEffect(() => {
        const svg = d3.select(barSvgRef.current);
        svg.select('.tooltip').remove();
        let tooltip = svg
            .append('g')
            .attr('data-testid', 'barchart-tooltip')
            .attr('class', 'tooltip')
            .style('display', 'none');

        tooltip
            .append('rect')
            .attr('width', tooltipWidth)
            .attr('height', tooltipHeight)
            .attr('fill', tooltipBackground)
            .attr('rx', tooltipBorderRadius)
            .attr('ry', tooltipBorderRadius)
            .attr('stroke-width', tooltipStroke)
            .attr('stroke', tooltipStrokeColor);

        tooltip
            .append('text')
            .attr('x', tooltipWidth / 2)
            .attr('dy', tooltipHeight / 2)
            .attr('dominant-baseline', 'central')
            .style('text-anchor', 'middle')
            .style('fill', tooltipFontColor)
            .attr('font-size', '12px')
            .attr('font-weight', 600);
    }, [data]);

    return (
        <div data-testid="barchart-container">
            <svg
                width={width + marginWidth}
                height={height + marginHeight}
                data-testid="barchart-svg"
            >
                <g ref={barSvgRef} data-testid="barchart-content" />
                <g ref={barNamesRef} data-testid="barchart-names-content" />
            </svg>
        </div>
    );
};

BarChart.propTypes = {
    /** Array of objects in the format of {name : "", value : 0}  */
    data: PropTypes.array.isRequired,
    /** Width of the entire chart container  */
    width: PropTypes.number,
    /** Height of the entire chart container  */
    height: PropTypes.number,
    /** The maximum value from the data. If omitted, it's calculated automatically  */
    domain: PropTypes.number,
    /** Margin on the vertical axis  */
    marginHeight: PropTypes.number,
    /** Margin on the horizontal axis  */
    marginWidth: PropTypes.number,
    /** Translate the charts on the horizontal axis  */
    translateX: PropTypes.number,
    /** Translate the charts on the vertical axis  */
    translateY: PropTypes.number,
    /** Styles object to adjust bar, tooltip, names styling  */
    styling: PropTypes.object,
    /** Can either be HORIZONTAL or VERTICAL  */
    direction: PropTypes.oneOf(Object.values(BARCHART_DIRECTION)),
    /** Can be : START, MIDDLE, END  */
    labelPosition: PropTypes.oneOf(Object.values(BARCHART_LABEL_POSITION)),
    /** A function that can process the label text : (text) => { return processedText;}  */
    labelFormatter: PropTypes.func,
    /** A function that can process the tooltip text : (dataObject) => { return processedTooltip}  */
    tooltipFormatter: PropTypes.func,
    /** If enabled, tooltips will always show when hovering over a bar  */
    alwaysOnTooltips: PropTypes.bool,
    /** If a bar's value is lower than this, a tooltip will show for it  */
    tooltipShowSize: PropTypes.number,
    /** Enable/Disable bar chart names  */
    withNames: PropTypes.bool,
};

BarChart.defaultProps = {
    width: 300,
    height: 400,
    direction: BARCHART_DIRECTION.VERTICAL,
    translateX: 0,
    translateY: 0,
    marginWidth: 0,
    marginHeight: 0,
    labelPosition: BARCHART_LABEL_POSITION.MIDDLE,
    tooltipShowSize: 40,
};
