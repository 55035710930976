export const BARCHART_DIRECTION = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal',
};

export const BARCHART_LABEL_POSITION = {
    START: 'start',
    MIDDLE: 'middle',
    END: 'end',
    END_OF_DOMAIN: 'end_of_domain',
};

export const DEFAULT_STYLING = {
    backgroundColor: null,
    spacing: 0,
    fontWeight: 400,
    barWidth: 30,
    barBorderRadius: 0,
    barFontSize: 12,
    barStroke: 0,
    barStrokeColor: 'black',
    //Bar Label
    barLabelColor: 'white',
    barLabelSecondaryAxisOffset: 0,
    barLabelMainAxisOffset: 0,
    //Bar naming styles
    barNamesMainAxisOffset: 0,
    barNamesSecondaryAxisOffset: 0,
    barNamesColor: 'black',
    barNamesFontSize: 12,
    barNamesWidth: 70,
    //Tooltip styles
    tooltipWidth: 25,
    tooltipHeight: 18,
    tooltipBackground: 'white',
    tooltipFontColor: 'black',
    tooltipBorderRadius: 4,
    tooltipStroke: 0,
    tooltipStrokeColor: 'black',
    tooltipOffsetX: 0,
    tooltipOffsetY: 0,
};
