import React from "react";
import SubSection from "../SubSection/SubSection";
import { SMALL_WIDTH } from "../SubSection/SubSection.styles";
import { terrain } from "../data/data";

const Terrain = () => (
  <SubSection
    width="25vw"
    imgArray={[
      "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Contact.png",
      "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Mail_big.png",
    ]}
    title="Terrain"
    widthFixedSize={SMALL_WIDTH}
  >
    <ul>
      {terrain.map((text, index) => (
        <li key={index}>{text}</li>
      ))}
    </ul>
  </SubSection>
);

export default Terrain;
