import styled from "styled-components";

export const QuestionnaireDownload = styled.a`
    display:flex;
    align-self:flex-end;
    text-decoration: none;
    color:#272727;
    cursor: pointer;
    margin-left:5px;
    margin-top: 7px;

    @media only screen and (max-width: 550px) {
        margin-right:2.5rem;
    }
    @media only screen and (max-width: 450px) {
        margin-right:0;
        align-self:center;
    }
`
export const Img =  styled.img`
    margin-right:5px;
`