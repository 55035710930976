import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import {
    DEFAULT_STYLING,
    BARCHART_DIRECTION,
    BARCHART_LABEL_POSITION,
    scaleStackedBarChart,
    calculateTooltipPosition,
    calculateXYCoordinatesStackedBarChart,
    calculateLabelPositionStackedBarChart,
} from '../data'

/**
 * @typedef Styling
 * @prop {Array<{name: string, [key: string]: any}>} data
 * @prop {number} [spacing]
 * @prop {React.CSSProperties['width']} [barWidth]
 * @prop {React.SVGAttributes['stroke']} [barStroke]
 * @prop {React.CSSProperties['fontWeight']} [fontWeight]
 * @prop {React.CSSProperties['fontSize']} [barFontSize]
 * @prop {React.CSSProperties['color']} [barLabelColor]
 * @prop {React.CSSProperties['color']} [barStrokeColor]
 * @prop {number} [barLabelMainAxisOffset]
 * @prop {number} [barLabelSecondaryAxisOffset]
 * @prop {React.CSSProperties['width']} [tooltipWidth]
 * @prop {React.CSSProperties['height']} [tooltipHeight]
 * @prop {React.SVGAttributes['stroke']} [tooltipStroke]
 * @prop {number} [tooltipOffsetX]
 * @prop {number} [tooltipOffsetY]
 * @prop {React.CSSProperties['color']} [tooltipFontColor]
 * @prop {React.SVGAttributes['fill']} [tooltipBackground]
 * @prop {React.CSSProperties['color']} [tooltipStrokeColor]
 * @prop {React.CSSProperties['borderRadius']} [tooltipBorderRadius]
 */

/**
 * @typedef Props
 * @prop {Array<Data>} data
 * @prop {Array<string>} stackKeys
 * @prop {Array<React.CSSProperties['color']>} stackColors
 * @prop {React.CSSProperties['width']} [width]
 * @prop {React.CSSProperties['height']} [height]
 * @prop {number} [domain]
 * @prop {Styling} [styling]
 * @prop {string} [direction]
 * @prop {React.CSSProperties['translateX']} [translateX]
 * @prop {React.CSSProperties['translateY']} [translateY]
 * @prop {React.CSSProperties['width']} [marginWidth]
 * @prop {React.CSSProperties['height']} [marginHeight]
 * @prop {string} [labelPosition]
 * @prop {(value: string) => string} [labelFormatter]
 * @prop {number} [tooltipShowSize]
 * @prop {(data: any) => any} [tooltipFormatter]
 * @prop {boolean} [alwaysOnTooltips]
 */

/**
 * @type {React.FC<Props>}
 * @return {JSX.Element}
 */
export const StackedBarChart = ({
    data,
    width,
    height,
    domain,
    styling,
    direction,
    stackKeys,
    translateX,
    translateY,
    stackColors,
    marginWidth,
    marginHeight,
    labelPosition,
    labelFormatter,
    tooltipShowSize,
    tooltipFormatter,
    alwaysOnTooltips,
}) => {
    const barSvgRef = useRef(null);
    const {
        spacing,
        barWidth,
        barStroke,
        fontWeight,
        barFontSize,
        barLabelColor,
        barStrokeColor,
        barBorderRadius,
        barLabelMainAxisOffset,
        barLabelSecondaryAxisOffset,
        tooltipWidth,
        tooltipHeight,
        tooltipStroke,
        tooltipOffsetX,
        tooltipOffsetY,
        tooltipFontColor,
        tooltipBackground,
        tooltipStrokeColor,
        tooltipBorderRadius,
    } = { ...DEFAULT_STYLING, ...styling };

    const calculateLabelValues = (lowerBound, upperBound) => {
        const value = upperBound - lowerBound;
        return labelFormatter ? labelFormatter(value) : value;
    };

    const isBarTooSmall = (lowerBound, upperBound, scale) => {
        return scale(upperBound) - scale(lowerBound) < tooltipShowSize;
    };

    /* Draw functions */
    const drawVertical = layers => {
        const { xScale, yScale } = scaleStackedBarChart({ direction, width, data, height, domain, stackKeys, spacing });

        layers
            .selectAll('rect')
            .data(d => d)
            .join('rect')
            .merge(layers)
            .attr('width', barWidth || xScale.bandwidth())
            .attr('y', d => yScale(d[1]) + spacing)
            .attr('x', d => xScale(d.data.name))
            .attr('rx', barBorderRadius)
            .attr('ry', barBorderRadius)
            .attr('stroke-width', barStroke)
            .attr('stroke', barStrokeColor)
            .attr('height', d => yScale(d[0]) - yScale(d[1]) - spacing)
            .attr('data-testid', 'stacked-barchart-rect')
            .on('mouseover', function (d, datum) {
                if (isBarTooSmall(datum[1], datum[0], yScale) || alwaysOnTooltips) {
                    d3.select(barSvgRef.current).select('.tooltip').style('display', null);
                }
            })
            .on('mouseout', function () {
                d3.select(barSvgRef.current).select('.tooltip').style('display', 'none');
            })
            .on('mousemove', function (d, datum) {
                const { xPosition, yPosition } = calculateXYCoordinatesStackedBarChart({
                    xCoordinate: d3.pointer(d)[0],
                    yCoordinate: d3.pointer(d)[1],
                    tooltipOffsetX,
                    tooltipHeight,
                    tooltipWidth,
                    tooltipOffsetY,
                });
                calculateTooltipPosition({
                    xPosition,
                    yPosition,
                    dataObject: datum,
                    barSvgRef,
                    tooltipFormatter,
                    type: 'stackedBarChart',
                });
            });

        layers
            .selectAll('text')
            .data(d => d)
            .join('text')
            .attr('data-testid', 'stacked-barchart-text')
            .merge(layers)
            .attr(
                'y',
                d =>
                    calculateLabelPositionStackedBarChart({
                        lowerBound: d[0],
                        upperBound: d[1],
                        labelPosition,
                        scale: yScale,
                    }) + barLabelMainAxisOffset
            )
            .attr('x', d => xScale(d.data.name) + (barWidth || xScale.bandwidth()) / 3 + barLabelSecondaryAxisOffset)
            .attr('dy', '.75em')
            .style('fill', barLabelColor)
            .style('font-size', barFontSize)
            .style('font-weight', fontWeight)
            .text(function (d) {
                return !isBarTooSmall(d[1], d[0], yScale) ? calculateLabelValues(d[0], d[1]) : '';
            });
    };

    const drawHorizontal = layers => {
        const { xScale, yScale } = scaleStackedBarChart({ direction, width, data, height, domain, stackKeys, spacing });

        layers
            .selectAll('rect')
            .data(d => d)
            .join('rect')
            .merge(layers)
            .attr('width', d => xScale(d[1]) - xScale(d[0]) - spacing)
            .attr('y', d => yScale(d.data.name))
            .attr('x', d => xScale(d[0]) + spacing)
            .attr('height', barWidth)
            .attr('rx', barBorderRadius)
            .attr('ry', barBorderRadius)
            .attr('stroke-width', barStroke)
            .attr('stroke', barStrokeColor)
            .attr('data-testid', 'stacked-barchart-rect')
            .on('mouseover', function (d, datum) {
                if (isBarTooSmall(datum[0], datum[1], xScale) || alwaysOnTooltips) {
                    d3.select(barSvgRef.current).select('.tooltip').style('display', null);
                }
            })
            .on('mouseout', function () {
                d3.select(barSvgRef.current).select('.tooltip').style('display', 'none');
            })
            .on('mousemove', function (d, datum) {
                const { xPosition, yPosition } = calculateXYCoordinatesStackedBarChart({
                    xCoordinate: d3.pointer(d)[0],
                    yCoordinate: d3.pointer(d)[1],
                    tooltipOffsetX,
                    tooltipHeight,
                    tooltipWidth,
                    tooltipOffsetY,
                });
                calculateTooltipPosition({
                    xPosition,
                    yPosition,
                    dataObject: datum,
                    barSvgRef,
                    tooltipFormatter,
                    type: 'stackedBarChart',
                });
            });

        layers
            .selectAll('text')
            .data(d => d)
            .join('text')
            .merge(layers)
            .attr('y', d => yScale(d.data.name) + (barWidth || yScale.bandwidth()) / 3 + barLabelSecondaryAxisOffset)
            .attr('x', d =>
                calculateLabelPositionStackedBarChart({
                    lowerBound: d[0],
                    upperBound: d[1],
                    labelPosition,
                    scale: xScale,
                })
            )
            .attr('dy', '.75em')
            .attr('text-anchor', 'middle')
            .attr('data-testid', 'stacked-barchart-text')
            .style('fill', barLabelColor)
            .style('font-size', barFontSize)
            .style('font-weight', fontWeight)
            .text(function (d) {
                return !isBarTooSmall(d[0], d[1], xScale) ? calculateLabelValues(d[0], d[1]) : '';
            });
    };

    /* Stack setup and initial paint */
    useEffect(() => {
        let stackGen = d3.stack().keys(stackKeys);
        let stackedSeries = stackGen(data);
        let colorScale = d3.scaleOrdinal().domain(stackKeys).range(stackColors);

        let layers = d3
            .select(barSvgRef.current)
            .select('g')
            .selectAll('g.series')
            .data(stackedSeries)
            .join('g')
            .classed('series', true)
            .attr('data-testid', 'stacked-barchart-series')
            .style('fill', d => colorScale(d.key));

        switch (direction) {
            case BARCHART_DIRECTION.HORIZONTAL:
                drawHorizontal(layers);
                break;
            default:
                drawVertical(layers);
        }
    }, [data]);

    /* Add the tooltip */
    useEffect(() => {
        const svg = d3.select(barSvgRef.current);
        const tooltip = svg
            .append('g')
            .attr('class', 'tooltip')
            .attr('data-testid', 'stacked-barchart-tooltip')
            .style('display', 'none');

        tooltip
            .append('rect')
            .attr('width', tooltipWidth)
            .attr('height', tooltipHeight)
            .attr('fill', tooltipBackground)
            .attr('rx', tooltipBorderRadius)
            .attr('ry', tooltipBorderRadius)
            .attr('stroke-width', tooltipStroke)
            .attr('stroke', tooltipStrokeColor);

        tooltip
            .append('text')
            .attr('x', tooltipWidth / 2)
            .attr('dy', tooltipHeight / 2)
            .attr('dominant-baseline', 'central')
            .style('text-anchor', 'middle')
            .style('fill', tooltipFontColor)
            .attr('font-size', '12px')
            .attr('font-weight', 'bold');
    }, []);

    useEffect(() => {
        let group = d3.select(barSvgRef.current);
        group.style('transform', `translate(${translateX}px,${translateY}px)`);
    }, [translateX, translateY]);

    return (
        <div data-testid="stacked-barchart-container">
            <svg
                data-testid="stacked-barchart-svg"
                ref={barSvgRef}
                width={width + marginWidth}
                height={height + marginHeight}
            >
                <g />
            </svg>
        </div>
    );
};

StackedBarChart.propTypes = {
    /** Array of objects in the format of {name : "", value : 0}  */
    data: PropTypes.array.isRequired,
    /** Array that contains the keys from the data that will be selected to be displayed  */
    stackKeys: PropTypes.array.isRequired,
    /** Array that contains, in same order as the stack keys, the color for each bar  */
    stackColors: PropTypes.array.isRequired,
    /** The maximum value from the data. If omitted, it's calculated automatically  */
    domain: PropTypes.number,
    /** Width of the entire chart container  */
    width: PropTypes.number,
    /** Height of the entire chart container  */
    height: PropTypes.number,
    /** Margin on the vertical axis  */
    marginHeight: PropTypes.number,
    /** Margin on the horizontal axis  */
    marginWidth: PropTypes.number,
    /** Translate the charts on the vertical axis  */
    translateX: PropTypes.number,
    /** Translate the charts on the vertical axis  */
    translateY: PropTypes.number,
    /** Can either be HORIZONTAL or VERTICAL  */
    direction: PropTypes.oneOf(Object.values(BARCHART_DIRECTION)),
    /** A function that can process the label text : (text) => { return processedText;}  */
    labelFormatter: PropTypes.func,
    /**  A function that can process the label text : (data) => { return processedText;}  */
    tooltipFormatter: PropTypes.func,
    /** Can be : START, MIDDLE, END  */
    labelPosition: PropTypes.oneOf(Object.values(BARCHART_LABEL_POSITION)),
    /** If enabled, tooltips will always show when hovering over a bar  */
    alwaysOnTooltips: PropTypes.bool,
    /** If a bar's value is lower than this, a tooltip will show for it  */
    tooltipShowSize: PropTypes.number,
    /** Styles object to adjust bar, tooltip, names styling  */
    styling: PropTypes.object,
};

StackedBarChart.defaultProps = {
    width: 300,
    height: 400,
    direction: BARCHART_DIRECTION.VERTICAL,
    translateX: 0,
    translateY: 0,
    marginWidth: 0,
    marginHeight: 0,
    labelPosition: BARCHART_LABEL_POSITION.MIDDLE,
    tooltipShowSize: 20,
};
