import React from "react";
import SubSection from "../SubSection/SubSection";
import { LARGE_WIDTH } from "../SubSection/SubSection.styles";
import {
  ChartHolder,
  Chart,
  Bullet,
  BulletHolder,
  BulletText,
  BulletTextWithList,
  AllBulletsHolder,
} from "./Questionnaire.styles.";
import { QuestionnaireArrows } from "../../../common/assets/QuestionnaireArrows";

const Questionnaire = () => (
  <SubSection
    imgArray={[
      "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Questionnaire.png",
    ]}
    title="Questionnaire"
    width="65vw"
    widthFixedSize={LARGE_WIDTH}
  >
    <ChartHolder>
      <AllBulletsHolder up>
        <BulletHolder height="6.5rem">
          <Bullet
            src={
              "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Questionnaire_up.png"
            }
            alt="dot"
          />
          <BulletText>
            Satisfaction globale <u>spontanée</u> vis-à-vis d’OCP
          </BulletText>
        </BulletHolder>
        <BulletHolder height="6.5rem">
          <Bullet
            src={
              "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Questionnaire_up.png"
            }
            alt="dot"
          />
          <BulletText>
            Satisfaction globale <u>finale</u> vis-à-vis d’OCP
          </BulletText>
        </BulletHolder>
      </AllBulletsHolder>
     <QuestionnaireArrows/>
      <AllBulletsHolder>
        <div>
        <BulletHolder color="#404040" alignItems="flex-end" marginTop="-40px" height="4.5rem">
          <Bullet
            src={
              "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Questionnaire_down.png"
            }
            alt="dot"
           
          />
        </BulletHolder>
        
        <BulletTextWithList>
          <p>Satisfaction détaillée vis-à-vis d’OCP par thème et par critère</p>
          <ul style={{ textAlign: "left" }}>
            <li>Offre de service de répartition</li>
            <li>Traitement des dysfonctionnements</li>
            <li>
              Accessibilité des interlocuteurs OCP et de l’information mise à
              disposition
            </li>
            <li>Relations commerciales</li>
          </ul>
        </BulletTextWithList>
        </div>
       

        <BulletHolder color="#404040" alignItems="flex-end" marginLeft="-40px" marginTop="-40px" height="4.5rem">
          <Bullet
            src={
              "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Questionnaire_down.png"
            }
            alt="dot"
           
          />
           <BulletText>Recommandation d’OCP et raisons</BulletText>
        </BulletHolder>
       
      </AllBulletsHolder>
    </ChartHolder>
  </SubSection>
);

export default Questionnaire;
