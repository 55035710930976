import styled from "styled-components";

export const Holder = styled.div`
    font:80% Helvetica Regular, Arial, Myriad Pro;
    color:#272727;
    margin: 1vh 1vw;
    width:22vw;
    box-shadow: 0px 0px 10px #00000030;
    border-radius: 3px;
    
    @media only screen and (max-width: 1024px) {
        width:15rem;
    } 
`
