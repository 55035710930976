import { connect } from "react-redux";

import Login from "./Login.page";
import { loginInit, setAuthStatus } from "../data/actions";
import { getErrMessage } from "../data/selectors";

const mapStateToProps = state => ({
    errMessage: getErrMessage(state),
});

const mapDispatchToProps = {
    loginInit,
    setAuthStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
