import { connect } from "react-redux";

import ExportSection from "./ExportSection"
import { getRoleName } from "../data/selectors";
import { setLoadingStatus } from "../../../../HarrisLoading/data/actions";

const mapStateToProps = state => ({
    roleName: getRoleName(state)
});

const mapDispatchToProps = {
    setLoadingStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportSection);
