import styled from "styled-components";

export const Img = styled.img`
    height: 35px;
`

export const ExportWrapper = styled.div`
    padding: 30px;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span {
        font-size: 14px;
        color: #272727;
    }

`