import { combineReducers } from "redux";
import { SET_LOADING_STATUS } from "./actions";

const loadingStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_LOADING_STATUS:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    loadingStatus: loadingStatusReducer
});
