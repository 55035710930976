import React from "react";

import {
  ResultSection,
  MainOption,
  SectionTitle,
  ActualYearText,
  LastYearText,
  SmallOptions,
  SubOption,
  SubOptionActualYear,
  SubOptionLastYear,
} from "../../MainResultsSection.styles";

const SatisfactionTab = ({ data }) => {
  const actualYearIdx = data?.Years.findIndex((el) => el.IsCurrentYear);
  const lastYearIdx = actualYearIdx - 1;

  if (actualYearIdx === -1) return null;

  const mainResultActualYear = Math.round(
    data?.Years[actualYearIdx].Options[0].Perc
  );
  const mainResultLastYear = Math.round(
    data?.Years[lastYearIdx].Options[0].Perc
  );
  const isOkMainSection =
    mainResultActualYear === mainResultLastYear
      ? undefined
      : mainResultActualYear > mainResultLastYear;

  const subsectionOneActualYear = Math.round(
    data?.Years[actualYearIdx].Options[1].Perc
  );
  const subsectionOneLastYear = Math.round(
    data?.Years[lastYearIdx].Options[1].Perc
  );
  const isOkSubSectionOne =
    subsectionOneActualYear === subsectionOneLastYear
      ? undefined
      : subsectionOneActualYear > subsectionOneLastYear;

  const subSectionTwoActualYear = Math.round(
    data?.Years[actualYearIdx].Options[2].Perc
  );
  const subSectionTwoLastYear = Math.round(
    data?.Years[lastYearIdx].Options[2].Perc
  );
  const isOkSubSectionTwo =
    subSectionTwoActualYear === subSectionTwoLastYear
      ? undefined
      : subSectionTwoActualYear > subSectionTwoLastYear;

  const clientsOneNrRespondents =
    data?.Years[actualYearIdx].Options[1].NrRespondents >= 10;
  const clientsTwoNrRespondents =
    data?.Years[actualYearIdx].Options[2].NrRespondents >= 10;
  return (
    <ResultSection width={45}>
      <SectionTitle>{data?.QText}</SectionTitle>
      <MainOption>
        <p>{data?.Years[actualYearIdx].Options[0].OptionText}</p>
        <ActualYearText isOk={isOkMainSection}>
          {mainResultActualYear}%
          {isOkMainSection === undefined ? null : isOkMainSection ? (
            <img
              src={
                "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/okArrow_small.svg"
              }
              alt="Ok arrow green"
            />
          ) : (
            <img
              src={
                "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/redArrow_small.svg"
              }
              alt="Ok arrow red"
            />
          )}
        </ActualYearText>
        <LastYearText>
          {mainResultLastYear}% ({data?.Years[lastYearIdx].YearText})
        </LastYearText>
      </MainOption>
      <SmallOptions>
        <SubOption>
          <p>{data?.Years[actualYearIdx].Options[1].OptionText}</p>
          {clientsOneNrRespondents ? (
            <SubOptionActualYear isOk={isOkSubSectionOne}>
              {subsectionOneActualYear}%
            </SubOptionActualYear>
          ) : (
            <SubOptionActualYear>-</SubOptionActualYear>
          )}
          <SubOptionLastYear>
            {subsectionOneLastYear}% ({data?.Years[lastYearIdx].YearText})
          </SubOptionLastYear>
        </SubOption>
        <hr />
        <SubOption>
          <p>{data?.Years[actualYearIdx].Options[2].OptionText}</p>
          {clientsTwoNrRespondents ? (
            <SubOptionActualYear isOk={isOkSubSectionTwo}>
              {subSectionTwoActualYear}%
            </SubOptionActualYear>
          ) : (
            <SubOptionActualYear> - </SubOptionActualYear>
          )}
          <SubOptionLastYear>
            {subSectionTwoLastYear}% ({data?.Years[lastYearIdx].YearText})
          </SubOptionLastYear>
        </SubOption>
      </SmallOptions>
    </ResultSection>
  );
};

export default SatisfactionTab;
