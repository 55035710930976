import React from 'react';
import "./App.css";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import LandingContainer from "./components/Landing/component/Landing.container";

const App = () => {

  return (
    <Router>
      <div className="App">
        <LandingContainer />
      </div>
    </Router>
  )
};

export default App;
