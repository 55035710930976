import { combineReducers } from "redux";
import { SET_SECTION_DATA, SET_BARCHART } from "./actions";

const barChart = (state = {}, action) => {
    switch (action.type) {
        case SET_BARCHART:
            return action.payload;
        default:
            return state;
    }
};

export const sectionData = (state = {}, action) => {
    switch (action.type) {
        case SET_SECTION_DATA:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    barChart,
    sectionData
});
