import styled from "styled-components";

export const ModalWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(39, 39, 39, .6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 40;
`

export const Modal = styled.div`
    width: 680px;
    height: 300px;
    background-color: #fff;
`

export const ModalHeader = styled.div`
    height: 50px;
    background-color: #009A74;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
        margin-right: 25px;
        font-size: 16px;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
`

export const ModalBody = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: calc(100% - 50px);
    flex-direction: column;

    p {
        font-size: 18px;
    }
`

export const ModalActions = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    button, a{
        width: 120px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        outline: none;
    }

    button:first-child {
        background-color: #fff;
        color: #272727;
        border: 1px solid #272727;
    }

    a{
        background-color: #009A74;
        color: #fff;
        border: 1px solid #009A74;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
`