import { combineReducers } from "redux";

import user from "../components/Header/data/reducer";
import login from "../components/Main/Login/data/reducer";
import mainResults from "../components/Main/Home/MainResultsSection/data/reducer";
import loading from "../components/HarrisLoading/data/reducer";

export const lastAction = (state = null, action) => {
    return action;
};

export default combineReducers({
    lastAction,
    user,
    login,
    mainResults,
    loading
});
