import React, { useEffect, useState } from "react";

import {
  DropdownWrapper,
  DropdownHeader,
  ArrowIcon,
  TextOption,
} from "./Dropdown.style";

const Dropdown = ({ data, option }) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const handleReportChange = (rapport) => {
    option.update(rapport);
    setShowDropDown(false);
  };

  return (
    <DropdownWrapper>
      <DropdownHeader onClick={() => setShowDropDown(!showDropDown)}>
        <TextOption>{option.tmpValue.ReportName}</TextOption>
        <ArrowIcon shouldRotate={showDropDown}>
          <img
            src={
              "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/arrow_small.svg"
            }
          />
        </ArrowIcon>
      </DropdownHeader>
      {showDropDown && (
        <ul>
          {data &&
            data.map((rapport, index) => (
              <li
                key={"mrs-" + index}
                onClick={() => handleReportChange(rapport)}
              >
                {rapport.ReportName}
              </li>
            ))}
        </ul>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
