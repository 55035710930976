import React, { useState } from "react";
import Section from "../Section/Section";
import {
  Img,
  TextHolder,
  P,
} from "./EditorialSection.style";

const EditorialSection = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Section height="250" title="Editorial" expand={showMore}>
      <Img
        src="https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/editorial.png"
        alt="editorial"
      />
      <TextHolder expand={showMore}>
        <P>
        Comme chaque année, OCP mesure la satisfaction de ses clients. Nous avons toujours de bons résultats mais certains indicateurs sont un peu en deçà de ce que nous avions l’année passée et méritent toute votre attention.
        </P>
        <P>
        Pour motiver les équipes en interne, vous trouverez de nouveau les podiums des meilleurs résultats et meilleures progressions sur chaque item.
        </P>
        <P>
        Félicitations à tous !
        </P>
      </TextHolder>
    </Section>
  );
};

export default EditorialSection;
