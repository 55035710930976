import React, { useState, useEffect } from 'react';
import { LoginHolder, Input, InvalidLabel, ForgotPass, SubmitButton } from "./Login.styles";
import { checkAuthStatus } from "../../../../serverApi";
import { withRouter } from 'react-router-dom';

const Login = ({ errMessage, loginInit, setAuthStatus }) => {
    const [email, setEmail] = useState(null);
    const [pass, setPass] = useState(null);

    const validEmail = email ? /\S+@\S+\.\S+/.test(email) : email === "" ? false : null;
    const validPass = pass ? pass.length > 0 : pass === "" ? false : null;
    const checkValidCredentials = validEmail && validPass;

    useEffect(() => {
        setAuthStatus(checkAuthStatus());
    }, []);

    useEffect(() => {
        if (errMessage !== '' && errMessage !== undefined) {
            setEmail("");
            setPass("");
        }
    }, [errMessage]);

    const handleLogin = () => {
        if (checkValidCredentials) {
            const userObject = {
                email, pass
            };
            loginInit(userObject);
        }
    };

    const handleEnter = e => e.charCode === 13 && handleLogin();

    return (<LoginHolder onKeyPress={handleEnter}>
        <Input placeholder="Email" onChange={e => setEmail(e.target.value)} valid={validEmail} value={email} />
        {email && !validEmail && <InvalidLabel>Veuillez verifier votre adresse email.</InvalidLabel>}
        <Input type="password" placeholder="Mot de passe" onChange={e => setPass(e.target.value)} valid={validPass} value={pass} />
        {email === "" && pass === "" && <p style={{ color: 'red' }}>{errMessage}</p>}
        <ForgotPass href="mailto:Calypso_OCP@harrisinteractive.eu?subject=Demande de réinitialisation de mot de passe – Calypso OCP&body=Je souhaite demander la réinitialisation de mon mot de passe pour l’outil de reporting Calypso OCP.  Merci.">Mot de passe oublié?</ForgotPass>
        <SubmitButton valid={checkValidCredentials} onClick={handleLogin}>SE CONNECTER</SubmitButton>
    </LoginHolder>)
}

export default withRouter(Login);
