import styled from "styled-components";

export const MapList = styled.div`
    display: flex;
    flex-direction: row;

    ul{
        ${({ listImg }) => listImg && `list-style-image: url(${listImg});`}

        li {
            font:inherit;
            color:#272727;
        }
    }
`