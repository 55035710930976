import { all } from 'redux-saga/effects';
import userDetailsSaga from "../components/Header/data/saga";
import userLoginSaga from "../components/Main/Login/data/saga";
import mainResultsSaga from "../components/Main/Home/MainResultsSection/data/saga";

export default function* saga() {
    yield all([
        userDetailsSaga(),
        userLoginSaga(),
        mainResultsSaga()
    ]);
}

