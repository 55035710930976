import styled from "styled-components";

export const LoginTitle = styled.p`
    font: 22pt Helvetica Regular, Arial, Myriad Pro;
    color:#009a74;
    margin:2rem;
    text-align:center;
    @media only screen and (max-width: 730px) {
        margin:1rem;
        font-size:16pt;
    }
    @media only screen and (max-width: 550px) {
        margin:0.5rem;
        font-size:14pt;
    }
`

export const LoginHolder = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:37rem;
    height:25rem;
    box-shadow: 0 0.1rem 0.8rem #DEDEDE;
    @media only screen and (max-width: 730px) {
        width:30rem;
    }
    @media only screen and (max-width: 550px) {
        width:25rem;
    }
    @media only screen and (max-width: 450px) {
        width:14rem;
    }
    @media only screen and (max-width: 1024px) {
        height:22rem;
    }
`

export const Input = styled.input`
    width:27rem;
    font: 14pt Helvetica Regular, Arial, Myriad Pro;
    color:${({ valid }) => valid !== null ? (valid ? "#009a74" : "#AA4362") : "#272727"};
    padding-top:2rem;
    padding-bottom:0.5rem;
    border:none;
    border-bottom:0.1rem solid ${({ valid }) => valid !== null ? (valid ? "#009a74" : "#AA4362") : "#d4d4d4"};
    outline:none;

    &::placeholder {
        font-style: italic;
        color:#d4d4d4;
    }

    @media only screen and (max-width: 550px) {
        width:20rem;
    }
    @media only screen and (max-width: 450px) {
        width:10rem;
    }
`

export const InvalidLabel = styled.label`
    color:#AA4362;
    font:10pt Helvetica Regular, Arial, Myriad Pro;
    font-style:italic;
    align-self:flex-start;
    margin-left:5rem;
`

export const ForgotPass = styled.a`
    display:flex;
    align-self:flex-end;
    margin-right:5rem;
    margin-top:0.5rem;
    font: 10pt Helvetica Regular, Arial, Myriad Pro;
    color:#272727;
    cursor: pointer;

    @media only screen and (max-width: 550px) {
        margin-right:2.5rem;
    }
    @media only screen and (max-width: 450px) {
        margin-right:0;
        align-self:center;
    }
`

export const SubmitButton = styled.button`
    width:27rem;
    padding:0.7rem;
    margin:4rem;
    font: 16pt Helvetica Regular, Arial, Myriad Pro;
    color:#FFF;
    background-color:${({ valid }) => valid ? "#009a74" : "#D4D4D4"};
    border-radius:2rem;
    outline:none;
    border:none;
    cursor: pointer;

    &:active {
        color:transparent;
    }

    @media only screen and (max-width: 550px) {
        width:18rem;
    }
    @media only screen and (max-width: 450px) {
        width:12rem;
        font-size:14pt;
        padding:0.4rem;
    }
`