import React from "react";
import Section from "../Section/Section";
import { QuestionnaireDownload, Img } from "./DocumentsSection.style";
import { baseQuestionnairePath, baseGuidePath } from "../../../../utils";

const DocumentsSection = () => (
  <Section title="Documents annexes" small>
    <QuestionnaireDownload
      href={`${baseQuestionnairePath}`}
      target="_blank"
      download
    >
      <Img
        src={
          "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Questionnaire_link.png"
        }
        alt="link"
      />
      Questionnaire
    </QuestionnaireDownload>
    <QuestionnaireDownload
      href={`${baseGuidePath}`}
      target="_blank"
      download
    >
      <Img
        src={
          "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Questionnaire_link.png"
        }
        alt="link"
      />
      Guide utilisateur
    </QuestionnaireDownload>
  </Section>
);

export default DocumentsSection;
