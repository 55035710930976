import styled from "styled-components";

export const ArrowImg = styled.img`
    margin-left:10rem;

    @media only screen and (max-width: 1650px) {
        width:10vw;
    }

    @media only screen and (max-width: 1024px) {
        margin-left:8rem;
        width:8rem;
    }
`