import React from "react";
import { Route, Redirect } from "react-router-dom";

import { getRoutePathWithPrefix } from "../../../utils";
import HarrisLoading from "../../HarrisLoading/components/HarrisLoading";
import LoginPage from "../../../pages/LoginPage";
import PrivateRoute from "../../PrivateRoute/PrivateRoute";
import HomePage from "../../../pages/HomePage";

const Landing = ({ isLoading, isAuthenticated }) => {
    return (
        <>
            {isLoading === true && <HarrisLoading />}
            {isAuthenticated ? <Redirect to={getRoutePathWithPrefix('home')} /> :
                <Redirect from="/" to={getRoutePathWithPrefix('login')} />}
            <Route exact path={getRoutePathWithPrefix('login')} component={LoginPage} />
            <PrivateRoute path={getRoutePathWithPrefix('home')} component={HomePage} />
        </>
    )
};

export default Landing;