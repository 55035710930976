import { connect } from "react-redux";
import { getLoadingStatus, getAuthStatus } from "../data/selectors";

import Landing from "./Landing.page";

const mapStateToProps = state => ({
    isAuthenticated: getAuthStatus(state),
    isLoading: getLoadingStatus(state)
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
