import { all, call, put, takeLatest } from "@redux-saga/core/effects";

import * as actions from "./actions";
import { fetchMainResultsRequest, fetchBarChartRequest } from "../../../../../serverApi";
import { setLoadingStatus } from "../../../../HarrisLoading/data/actions";

function* getMainResultsDataSaga(action) {
    try {
        yield put(setLoadingStatus(true));
        const barChartData = yield call(fetchBarChartRequest, action.payload);
        const mainData = yield call(fetchMainResultsRequest, action.payload);
        yield put(actions.setSectionData(mainData));
        yield put(actions.setBarChart(barChartData))
        yield put(setLoadingStatus(false));
    } catch (error) {
        yield put(setLoadingStatus(false));
    }

}

export default function* saga() {
    yield all([
        takeLatest(actions.GET_MAIN_RESULTS_DATA, getMainResultsDataSaga),
    ]);
}