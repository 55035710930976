import React from "react";
import SubSection from "../SubSection/SubSection";
import { MEDIUM_WIDTH } from "../SubSection/SubSection.styles";
import { MapList } from "./Establissements.styles";
import { locations } from "../data/data";

const Establissements = () => (
  <SubSection
    imgArray={[
      "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Map.png",
    ]}
    title="Etablissements et segments de clientèle interrogés"
    width="37vw"
    widthFixedSize={MEDIUM_WIDTH}
  >
    <MapList
      listImg={
        "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/list-location-type.svg"
      }
    >
      <ul>
        {locations.map(
          (location, index) => index <= 14 && <li key={index}> {location}</li>
        )}
      </ul>
      <ul>
        {locations.map(
          (location, index) =>
            index > 14 && index < 30 && <li key={index}> {location}</li>
        )}
      </ul>
      <ul>
        {locations.map(
          (location, index) => index >= 30 && <li key={index}> {location}</li>
        )}
      </ul>
    </MapList>
  </SubSection>
);

export default Establissements;
