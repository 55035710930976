import React from "react";

import { Modal, ModalWrapper, ModalHeader, ModalBody, ModalActions } from "./ConfirmationModal.style";

const ConfirmationModal = ({ state }) => {

    return (
        <ModalWrapper>
            <Modal>
                <ModalHeader>
                    <span onClick={() => state.update({ isOpen: false })}>&#10005;</span>
                </ModalHeader>
                <ModalBody>
                    <p>Êtes-vous sûr de vouloir télécharger ce fichier?</p>
                    <ModalActions>
                        <button onClick={() => state.update({ isOpen: false })}>Annuler</button>
                        <a href={state.value.url} onClick={() => state.update({ isOpen: false })} target="_blank" download>Télécharger</a>
                    </ModalActions>
                </ModalBody>
            </Modal>
        </ModalWrapper>
    )

};

export default ConfirmationModal;