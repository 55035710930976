import styled from "styled-components";

export const SectionHolder = styled.div`
    width:${({ small }) => small ? "22vw" : "73vw"};
    margin:1vh 1vw;
    border-radius:0 0 0.1rem 0.1rem;
    box-shadow: 0px 0px 10px 2px #00000030;
    border-radius: 0.3rem;

    height: ${({ height }) => height && height + 'px'};

    
    @media only screen and (max-width: 1650px) {
        width:${({ small }) => !small && "70vw"};
    }

    @media only screen and (max-width: 1024px) {
        margin:0.55rem;
        width:${({ small }) => small ? "15rem" : "45rem"};
    }
`

export const SectionHeader = styled.div`
    font:20pt Helvetica Regular, Arial, Myriad Pro;
    color:#ffffff;
    background-color:#009a74;
    padding:0.4rem 1rem 0.6rem 1rem;
    border-radius:0.3rem 0.3rem 0 0;
    cursor:default;
    
    @media only screen and (max-width: 1650px) {
        font-size:18pt;
    }

    @media only screen and (max-width: 1245px) {
        font-size:16pt;
    }

    @media only screen and (max-width: 1024px) {
        font-size:15pt;
    }
`

export const SectionContent = styled.div`
    font-family:Helvetica Regular, Arial, Myriad Pro;
    font-size:15px;
    padding: 1rem 1em 2em 1em;
    cursor:default;
    ${({ center }) => center && `
        display:flex;
        flex-direction:column;
        align-items:center;
    `};

    @media only screen and (max-width: 1850px) {
        font-size: 14px;
    }

    @media only screen and (max-width: 1650px) {
        font-size:12px;
    }

    @media only screen and (max-width: 1345px) {
        font-size: 12px;
    }
`