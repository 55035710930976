import React from 'react';
import Header from "../components/Header/component/Header";
import MainLogin from "../components/Main/MainLogin";

const LoginPage = () =>
    <React.Fragment>
        <Header hideUserHead />
        {/*<div style={{margin:"0 auto",marginTop:"300px",fontSize:"18px"}}>Cet outil est actuellement en maintenance, merci de vous connecter plus tard. Nous sommes désolés pour la gêne occasionnée</div>*/}
        <MainLogin />
    </React.Fragment>


export default LoginPage;
