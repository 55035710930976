import { asyncActionHelper } from "../../../../utils";

export const LOGIN_INIT = "@@/LOGIN_INIT";
export const LOGIN_ACTION_GROUP = asyncActionHelper("@@/LOGIN");

export const SET_USER_DETAILS = "@@/SET_USER_DETAILS";
export const SET_AUTH_STATUS = "@@/SET_AUTH_STATUS";

export const loginInit = userDetails => ({
  type: LOGIN_INIT,
  payload: userDetails,
});

export const setAuthStatus = authStatus => ({
  type: SET_AUTH_STATUS,
  payload: authStatus,
});