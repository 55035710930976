import React, { useState, useEffect } from "react";
import SimpleSelectSection from "../../../SimpleSelectSection/SimpleSelectSection";
import { fetchUserReportsRequest } from "../../../../serverApi";

const LibrairieSection = () => {
    const [items, setItems] = useState([]);


    useEffect(() => {
        fetchUserReportsRequest().then((data) => setItems(data))
            .catch(error => console.log(error.message));
    }, [])




    return <>
        <SimpleSelectSection title={"Vos rapports"} data={items} selectedValue={"PPTFileLink"} type={2} selectedName={"PPTFileName"} selectedID={"ReportID"} defaultOption={"Sélectionner un rapport"} buttonName={"Consulter"} />
        <SimpleSelectSection title={"Vos posters"} data={items} selectedValue={"PosterFileLink"} type={3} selectedName={"PosterFileName"} selectedID={"ReportID"} defaultOption={"Sélectionner un poster"} buttonName={"Consulter"} />
        <SimpleSelectSection title={"Librairie de vos rapports"} data={items} selectedValue={"Link"} type={1} selectedName={"ReportName"} selectedID={"ReportID"} defaultOption={"Sélectionner un rapport"} buttonName={"Télécharger"} />
    </>


};

export default LibrairieSection;