import styled from "styled-components";

export const Select = styled.div`
    font:inherit;
    color:#272727;
    width:90%;
    border-radius:0.3rem;
    margin:1rem 0;
    padding:0.5rem;
    max-height: 30px;
    height: 30px;

    ul{
        width: max-content;
        min-width: calc(90% + 10px);
        max-height: 350px;
        overflow: auto;
        list-style-type: none;
        box-shadow: 0px 3px 10px #00000069;
        border: 1px solid #6B6B6B;
        border-radius: 3px;
        opacity: 1;
        background: #FFFFFF;
        margin: 0;
        float: right;
        padding-inline-start: 10px;
        position: relative;
    }

    > ul > li {
        padding: 5px 20px 5px 5px;
        font-size: 14px;
    }

    > ul > li:hover {
        background-color: #009A74;
        cursor: pointer;
    }

    @media only screen and (max-width: 1024px) {
        font-size:80%;
    } 
`

export const TextOption = styled.span`
    white-space: nowrap;
    overflow: hidden;
    margin-right: 12px;
`

export const DropdownHeader = styled.div`
        background: transparent linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #6B6B6B;
        border-radius: 3px;
        opacity: 1;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
`

export const ArrowIcon = styled.span`
    float: right;
    img {
        transform: ${({ shouldRotate }) => shouldRotate && 'rotate(180deg)'};
        user-select: none;
    }
`

export const DownloadButton = styled.a`
    font: inherit;
    color: ${props => props.disabled ? '#9c9c9c' : '#272727'};
    border-radius: 2rem;
    border: ${props => props.disabled ? '0.05rem solid #9c9c9c' : '0.05rem solid #272727'};
    background-color: transparent;
    outline: none;
    margin: 1rem;
    padding: 0.5rem 2rem 0.8rem 2rem;
    text-decoration: none;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
        &:hover {
        color: ${props => props.disabled ? '#9c9c9c' : '#009a74'};
        border-color: ${props => props.disabled ? '#9c9c9c' : '#00785a'};
    }

        &:active {
        color: ${props => props.disabled ? '#9c9c9c' : 'transparent'};
    }
`