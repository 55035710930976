import React from "react";
import SubSection from "../SubSection/SubSection";
import { MEDIUM_WIDTH } from "../SubSection/SubSection.styles";
import {
  Text,
  TitleTable,
  Table,
  TableDescription,
} from "./Echantillon.styles";
import { echantillon } from "../data/data";

const Echantillon = () => (
  <SubSection
    imgArray={[
      "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Echantillon.png",
    ]}
    title="Echantillon"
    width="37vw"
    widthFixedSize={MEDIUM_WIDTH}
  >
    <Text>{echantillon.text[0]}</Text>
    <Text className="secondText">{echantillon.text[1]}</Text>
    <TitleTable>{echantillon.table.title}</TitleTable>
    <Table>
      <thead>
        <tr>
          {echantillon.table.thead.map((th, index) =>
            index === 0 || index === 5 ? (
              <React.Fragment key={index}>
                <th className="break">{index === 5 && <div></div>}</th>
                <th>{th}</th>
              </React.Fragment>
            ) : (
              <th key={index}>{th}</th>
            )
          )}
        </tr>
      </thead>
      <tbody>
        <tr>
          {echantillon.table.tdata1.map((td, index) =>
            index === 5 ? (
              <React.Fragment key={index}>
                <td>{td}</td>
                <td className="break"></td>
              </React.Fragment>
            ) : (
              <td key={index}>{td}</td>
            )
          )}
        </tr>
        <tr>
          {echantillon.table.tdata2.map((td, index) =>
            index === 5 ? (
              <React.Fragment key={index}>
                <td>{td}</td>
                <td className="break"></td>
              </React.Fragment>
            ) : (
              <td key={index}>{td}</td>
            )
          )}
        </tr>
      </tbody>
    </Table>
    <TableDescription width="78%">
      {echantillon.table.description[0]}
    </TableDescription>
    <TableDescription>
      {echantillon.table.description[1]}{" "}
      <span>{echantillon.table.description[2]}</span>
    </TableDescription>
  </SubSection>
);

export default Echantillon;
