import React from "react";
import Section from "../Section/Section";
import { SubSectionsHolder } from "./SubSection/SubSection.styles";
import Donnees from "./1.Donnees/Donnees";
import Establissements from "./2.Establissements/Establissements";
import Terrain from "./3.Terrain/Terrain";
import Echantillon from "./4.Echantillon/Echantillon";
import Questionnaire from "./5.Questionnaire/Questionnaire";

const PresentationSection = () =>
    <Section title="Présentation du dispositif" center >
        <SubSectionsHolder>
            <Donnees />
            <Establissements />
        </SubSectionsHolder>
        <SubSectionsHolder>
            <Terrain />
            <Echantillon />
        </SubSectionsHolder>
        <SubSectionsHolder>
            <Questionnaire />
        </SubSectionsHolder>
    </Section >

export default PresentationSection;