import { all, call, put, takeLatest } from "@redux-saga/core/effects";

import * as actions from "./actions";
import { loginUser } from "../../../../serverApi";
import { getRoutePathWithPrefix } from "../../../../utils";
import { push } from 'connected-react-router';
import { setLoadingStatus } from "../../../HarrisLoading/data/actions";

export function* loginRequest(action) {
    try {
        const userDetailsObj = {
            email: action.payload.email,
            pass: action.payload.pass
        };
        yield put(actions.LOGIN_ACTION_GROUP.dispatchers.started());
        yield put(setLoadingStatus(true));
        const tokens = yield call(loginUser, userDetailsObj);
        yield put(actions.LOGIN_ACTION_GROUP.dispatchers.succeeded(tokens));
        yield put(setLoadingStatus(false));
        yield put(push(getRoutePathWithPrefix('home')));
    } catch (error) {
        yield put(actions.LOGIN_ACTION_GROUP.dispatchers.failed(error));
        yield put(setLoadingStatus(false));
    }
}

export default function* saga() {
    yield all([
        takeLatest(actions.LOGIN_INIT, loginRequest),
    ]);
}