import createSagaMiddleware from 'redux-saga';
import { createStore, compose, applyMiddleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import rootReducer from './reducer';
import rootSaga from './saga';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const middlewares = [
    sagaMiddleware,
    routerMiddleware(history)
];

// The store is initialized with these values
const initialState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(...middlewares)));

/* Additional Services */
store.subscribe(() => {
    const state = store.getState();
});

sagaMiddleware.run(rootSaga);
export default store;