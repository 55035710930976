import styled from "styled-components";

export const ChartHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    text-align: center;
    width:inherit;
    margin-top:4em;
`

export const Chart = styled.img`
    width: 40vw;
`

export const AllBulletsHolder = styled.div`
    display:grid;
    margin-bottom:-40px;
    grid-template-columns:${({ up }) => up ? "95% 27% 41%;margin-left:-105px;" : "85% 38% 33%;margin-left:22%"};

    @media only screen and (max-width: 1600px) {
        grid-template-columns:${({ up }) => up ? "87% 38% 30%;margin-left:-90px;" : "83% 42%;margin-left:30%;"};
    }

    @media only screen and (max-width: 1250px) {
        grid-template-columns:${({ up }) => up ?"87% 38% 30%;margin-left:-90px;" :"83% 42%;margin-left:30%;"};
    }
`

export const BulletHolder = styled.div`
    display:flex;
    flex-direction:row;
    height: ${({ height }) => height ? height : "3.5rem"};
    border-left: 0.1rem solid ${({ color }) => color ? color : "#028D70"};
    align-items: ${({ alignItems }) => alignItems ? alignItems : "flex-start"};
    margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : "-30px"};
    margin-top: -40px;
`

export const Bullet = styled.img`
    margin-left: -0.8rem;
    margin-top: ${({ marginTop }) => marginTop ? marginTop : "0"};
`
export const BulletText = styled.div`
    width: 10rem;
    padding:0 1rem;
    font:inherit;
    text-align:left;
    color:#272727;
    
    p {
        margin-bottom: 40px;
    }

    @media only screen and (max-width: 1500px) {
        width: 9rem;
    }

    @media only screen and (max-width: 1500px) {
        width: 9rem;
    }

    @media only screen and (max-width: 1245px) {
        width:10rem;
        font-size: 14px;
    }
    
    @media only screen and (max-width: 1024px) {
        width:10rem;
        font-weight:bold;
    }
`

export const BulletTextWithList = styled.div`
    width: 13rem;
    margin-bottom:-2rem;
    font:inherit;
    text-align:left;
    color:#272727;
    margin-top:-60px;
   
    
    ul {
        padding: 0;
        padding-left: 10px;
        width: calc(11rem - 5px);
        margin-left: 5px;
    }

    li {
        font-family: inherit;
        font-size: 70%;
    }

    p {
        width: 11rem;
        margin-left: 10px;
    }

    @media only screen and (max-width: 1245px) {
        width:16.5rem;
        
        p {
            width: 9rem;
        }
    }
    
    @media only screen and (max-width: 1024px) {
        width:13.5rem;
        font-weight:bold;
        li{
            font-weight:normal;
            font-size:inherit;
            width: calc(10rem - 5px);
        }
    }
`