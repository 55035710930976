import styled from "styled-components";

export const MainStyled = styled.main`
    display:flex;
    height:91vh;
    overflow:auto;
    cursor: default;
    ${({ center }) => center && `
        flex-direction:column;
        align-items:center;
    `}
`

export const MainSections = styled.main`
    display:flex;
    flex-direction:column;
`