import React from "react";
import {
  Img,
  ContactEmail,
  ContactWrapper,
  ContactText,
} from "./ContactSection.styles";
import { Holder } from "../../common/common.styles";

const ContactSection = () => (
  <Holder>
    <ContactWrapper>
      <span>Contact</span>
      <ContactText>
        Pour les levées d`anonymat, veuillez contacter Thomas Zanette
      </ContactText>
      <ContactEmail href="mailto:thomas.zanette@ocp.fr">
        <Img
          src={
            "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Mail_big.png"
          }
          alt="mail"
        />
        <p>thomas.zanette@ocp.fr</p>
      </ContactEmail>
    </ContactWrapper>
  </Holder>
);

export default ContactSection;
