import { connect } from "react-redux";

import MainResultsSection from "./MainResultsSection"
import { getSectionData, getUserDetails, getBarChart } from "../data/selectors";
import { getMainResultsData } from "../data/actions";
import { setLoadingStatus } from "../../../../HarrisLoading/data/actions";


const mapStateToProps = state => ({
    userDetails: getUserDetails(state),
    barChart: getBarChart(state),
    sectionData: getSectionData(state)
});

const mapDispatchToProps = {
    getMainResultsData,
    setLoadingStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(MainResultsSection);
