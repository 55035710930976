import React from "react";
import SubSection from "../SubSection/SubSection";
import { SMALL_WIDTH } from "../SubSection/SubSection.styles";
import { ArrowImg } from "./Donnees.styles";
import { donnees } from "../data/data";

const Donnees = () => (
  <SubSection
    width="25vw"
    imgArray={[
      "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Donnees_clients.png",
    ]}
    title="Données clients"
    widthFixedSize={SMALL_WIDTH}
  >
    <ul>
      {donnees.map((text, index) => (
        <li key={index}>{text}</li>
      ))}
    </ul>
    <ArrowImg
      className="arrowImg"
      src={"https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/Arrow.png"}
      alt="Title"
    />
  </SubSection>
);

export default Donnees;
