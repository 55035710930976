import * as d3 from 'd3';
import { BARCHART_DIRECTION, BARCHART_LABEL_POSITION } from './constants';

export const calculateDomain = ({ data, domain, stackKeys, type = 'barchart' }) => {
    const calculatedDomain = d3.max(data, d =>
        type === 'stackedBarChart' ? stackKeys.reduce((acc, curValue) => acc + d[curValue], 0) : d.value
    );

    if (!domain) {
        return calculatedDomain;
    }

    return domain < calculatedDomain ? calculatedDomain : domain;
};

export const scaleStackedBarChart = ({ direction, width, data, height, domain, stackKeys, spacing }) => {
    let xScale;
    let yScale;
    const paddingOffset = spacing * stackKeys.length;

    if (direction === BARCHART_DIRECTION.VERTICAL) {
        xScale = d3
            .scaleBand()
            .range([0, width])
            .domain(data.map(d => d.name))
            .padding(0.2);
        yScale = d3
            .scaleLinear()
            .domain([0, calculateDomain({ data, domain, stackKeys, type: 'stackedBarChart' })])
            .range([height - paddingOffset, 25]);
    }

    if (direction === BARCHART_DIRECTION.HORIZONTAL) {
        xScale = d3
            .scaleLinear()
            .domain([0, calculateDomain({ data, domain, stackKeys, type: 'stackedBarChart' })])
            .range([25, width - paddingOffset]);

        yScale = d3
            .scaleBand()
            .range([height, 0])
            .domain(data.map(d => d.name))
            .padding(0.2);
    }

    return {
        xScale,
        yScale,
    };
};

export const calculateLabelPositionStackedBarChart = ({ lowerBound, upperBound, labelPosition, scale }) => {
    switch (labelPosition) {
        case BARCHART_LABEL_POSITION.START:
            return scale(lowerBound) - 12;
        case BARCHART_LABEL_POSITION.MIDDLE:
            return scale(upperBound) + (scale(lowerBound) - scale(upperBound)) / 2;
        default:
            return scale(upperBound);
    }
};

export const calculateLabelPosition = ({
    data,
    bound,
    width = 300,
    height = 400,
    domain = 0,
    direction,
    labelPosition,
    barLabelMainAxisOffset = 0,
}) => {
    const scale = () =>
        direction === BARCHART_DIRECTION.VERTICAL
            ? d3
                  .scaleLinear()
                  .domain([0, calculateDomain({ data, domain })])
                  .range([height, 0])
            : d3
                  .scaleLinear()
                  .domain([0, calculateDomain({ data, domain })])
                  .range([0, width]);

    switch (labelPosition) {
        case BARCHART_LABEL_POSITION.START:
            switch (direction) {
                case BARCHART_DIRECTION.VERTICAL:
                    return height - 20 + barLabelMainAxisOffset;
                case BARCHART_DIRECTION.HORIZONTAL:
                    return 4 + barLabelMainAxisOffset;
            }
            break;
        case BARCHART_LABEL_POSITION.MIDDLE:
            switch (direction) {
                case BARCHART_DIRECTION.VERTICAL:
                    return height - (height - scale()(bound)) + (height - scale()(bound)) / 2 + barLabelMainAxisOffset;
                case BARCHART_DIRECTION.HORIZONTAL:
                    return scale()(bound) / 2 + barLabelMainAxisOffset;
            }
            break;
        case BARCHART_LABEL_POSITION.END:
            switch (direction) {
                case BARCHART_DIRECTION.VERTICAL:
                    return scale()(bound) + 10 + barLabelMainAxisOffset;
                case BARCHART_DIRECTION.HORIZONTAL:
                    return scale()(bound) - 18 + barLabelMainAxisOffset;
            }
            break;
        case BARCHART_LABEL_POSITION.END_OF_DOMAIN:
            switch (direction) {
                case BARCHART_DIRECTION.VERTICAL:
                    return scale()(calculateDomain({ data, domain })) + 10 + barLabelMainAxisOffset;
                case BARCHART_DIRECTION.HORIZONTAL:
                    return scale()(calculateDomain({ data, domain })) - 10 + barLabelMainAxisOffset;
            }
            break;
        default:
            return scale()(bound) / 2 + barLabelMainAxisOffset;
    }
};

export const calculateXYCoordinatesStackedBarChart = ({
    xCoordinate,
    yCoordinate,
    tooltipOffsetX,
    tooltipHeight,
    tooltipWidth,
    tooltipOffsetY,
}) => {
    const xPosition = xCoordinate - tooltipWidth / 2 + tooltipOffsetX;
    const yPosition = yCoordinate + tooltipHeight / 5 + tooltipOffsetY;

    return {
        xPosition,
        yPosition,
    };
};

export const calculateXYCoordinatesBarChart = ({
    xCoordinate,
    yCoordinate,
    tooltipOffsetX,
    tooltipHeight,
    tooltipOffsetY,
}) => {
    const xPosition = xCoordinate + 10 + tooltipOffsetX;
    const yPosition = yCoordinate + tooltipHeight / 4 + tooltipOffsetY;

    return {
        xPosition,
        yPosition,
    };
};

export const calculateTooltipPosition = ({
    xPosition,
    yPosition,
    dataObject,
    barSvgRef,
    tooltipFormatter,
    type = 'barchart',
}) => {
    const tooltip = d3.select(barSvgRef.current).select('.tooltip');
    tooltip.attr('transform', 'translate(' + xPosition + ',' + yPosition + ')');

    const tooltipContent = () => {
        switch (true) {
            case type === 'stackedBarChart': {
                return tooltipFormatter
                    ? tooltipFormatter(dataObject[1] - dataObject[0])
                    : dataObject[1] - dataObject[0];
            }

            default: {
                return tooltipFormatter ? tooltipFormatter(dataObject) : dataObject.value;
            }
        }
    };

    tooltip.select('text').text(tooltipContent());
};
