import React from 'react';
import '../../../App.css';
import { HarrisLoadingWrapper } from './HarrisLoading.style'


class HarrisLoading extends React.Component {
    render() {
        return (
            <HarrisLoadingWrapper>
                <div className="harris_loading">
                    <div className="ring ring1 center" style={{ display: 'block' }}></div>
                    <div className="ring ring2 center" style={{ display: 'block' }}></div>
                    < div className="ring ring3 center" style={{ display: 'block' }}></div>
                    <div className="ring ring4 center" style={{ display: 'block' }}></div>
                </div>
            </HarrisLoadingWrapper>
        );
    };
};

export default HarrisLoading;