import styled from "styled-components";

export const DropdownWrapper = styled.div`
font:inherit;
color:#272727;
width: 250px;
border-radius: 3px;
margin:1rem 0;
max-height: 30px;
height: 35px;

ul{
    width: max-content;
    max-height: 350px;
    overflow: auto;
    list-style-type: none;
    box-shadow: 0px 3px 10px #00000069;
    border: 1px solid #6B6B6B;
    border-radius: 3px;
    opacity: 1;
    background: #FFFFFF;
    margin: 0;
    padding-inline-start: 0;
    min-width: 250px;
    position: relative;
    z-index: 10;
}

> ul > li {
    padding: 5px 20px 5px 5px;
    margin: 0 5px;
    font-size: 14px;
}

> ul > li:hover {
    background-color: #009A74;
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    font-size:80%;
} 
`

export const TextOption = styled.span`
white-space: nowrap;
overflow: hidden;
margin-right: 12px;
`

export const DropdownHeader = styled.div`
    background: #fff;
    border: 1px solid #6B6B6B;
    border-radius: 3px;
    opacity: 1;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
`

export const ArrowIcon = styled.span`
float: right;
img {
    transform: ${({ shouldRotate }) => shouldRotate && 'rotate(180deg)'};
    user-select: none;
}
`