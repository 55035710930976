import React from "react";
import RecomandationTab from "./Tabs/RecomandationTab";
import SatisfactionTab from "./Tabs/SatisfactionTab";
import TauxTab from "./Tabs/TauxTab";
import { SECTION } from "../../../../../../utils";
import { MainResultsSubSectionsWrapper } from "../MainResultsSection.styles";


function selectedSection(QCode, data) {
    for (let i = 0; i < data.length; i++) {
        if (data[i].QCode === QCode) {
            return data[i]
        }
    }
}

const ResultsData = ({ data }) => {
    const satisfactionTabSection = selectedSection(SECTION.SATISFACTION_GLOBALE, data);
  //  const tauxLeftSection = selectedSection(SECTION.TAUX_LEFT, data);
  //  const tauxRightSection = selectedSection(SECTION.TAUX_RIGHT, data);
    const recommandationTabSection = selectedSection(SECTION.RECOMMANDATION, data);
    
    if (!data || !data.length > 0) return null; 

    return (
        <MainResultsSubSectionsWrapper>
            <SatisfactionTab data={satisfactionTabSection} />
            {/* <TauxTab data1={tauxLeftSection} data2={tauxRightSection} /> */}
            <RecomandationTab data={recommandationTabSection} />
        </MainResultsSubSectionsWrapper>
    )
};

export default ResultsData;
