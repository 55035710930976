import { all, call, put, race, select, take, takeEvery, takeLatest } from "@redux-saga/core/effects";

import * as actions from "./actions";
import { setAuthStatus } from "../../Main/Login/data/actions";
import { fetchUserDetailsRequest, resetToken } from "../../../serverApi";

function* getUserDetailsSaga(action) {
    try {
        yield put(actions.USER_DETAILS_ACTION_GROUP.dispatchers.started());
        const userDetails = yield call(fetchUserDetailsRequest);
        yield put(actions.setUserDetails(userDetails));
        yield put(actions.USER_DETAILS_ACTION_GROUP.dispatchers.succeeded());
    } catch (error) {
        yield put(actions.USER_DETAILS_ACTION_GROUP.dispatchers.failed(error));
        yield put(setAuthStatus(false));
        yield call(resetToken());
    }

}

export default function* saga() {
    yield all([
        takeLatest(actions.GET_USER_DETAILS, getUserDetailsSaga),
    ]);
}