import React from 'react';
import { MainStyled, MainSections } from "./Main.styles";
import EditorialSection from "./Home/EditorialSection/EditorialSection";
import PresentationSection from "./Home/PresentationSection/PresentationSection";
import RapportsSection from "./Home/RapportsSection/RapportsSection";
import LibrairieSection from "./Home/LibrairieSection/LibrairieSection";
import DocumentsSection from "./Home/DocumentsSection/DocumentsSection";
import ContactSection from "./Home/ContactSection/ContactSection";
import MainResultsSectionContainer from "./Home/MainResultsSection/component/MainResultsSection.container";
import ExportSectionContainer from './Home/ExportSection/component/ExportSection.container';

const MainHome = () =>
    <MainStyled>
        <MainSections>
            <EditorialSection />
            <MainResultsSectionContainer />
            <PresentationSection />
        </MainSections>
        <MainSections>
            <RapportsSection />
            <LibrairieSection />
            <DocumentsSection />
            <ExportSectionContainer />
            <ContactSection />
        </MainSections>
    </MainStyled>

export default MainHome;
