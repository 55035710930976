import React from "react";
import { HeaderStyled, ImgStyled } from "./Header.styles";
import UserHeadContainer from "./UserHead.container";

const Header = ({ hideUserHead }) => (
  <HeaderStyled>
    <ImgStyled
      src={"https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/logo.png"}
      alt="logo"
    />
    {!hideUserHead && <UserHeadContainer />}
  </HeaderStyled>
);

export default Header;
