import styled from "styled-components";

export const LeftSectionWrapper = styled.div`
    display:flex;
    flex-direction:column;
    margin-right:50px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;