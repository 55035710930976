import { combineReducers } from "redux";
import { LOGIN_ACTION_GROUP, SET_AUTH_STATUS } from "./actions";



export const authStatus = (state = {}, action) => {
    switch (action.type) {
        case LOGIN_ACTION_GROUP.actions.STARTED:
            return {
                ...state,
                isAuthenticated: false,
                errMessage: ''
            };
        case LOGIN_ACTION_GROUP.actions.SUCCEEDED:
            return {
                ...state,
                isAuthenticated: true,
                errMessage: ''
            };
        case LOGIN_ACTION_GROUP.actions.FAILED:
            return {
                ...state,
                isAuthenticated: false,
                errMessage: action.payload.message
            };
        case SET_AUTH_STATUS:
            return {
                ...state,
                isAuthenticated: action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({
    authStatus,
});
