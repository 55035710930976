import styled from 'styled-components';

export const HarrisLoadingWrapper = styled.div`
position:fixed;
width:100%;
height:100%;
left:0;
top:0;
background-color:rgba(0,0,0,0.3);
z-index:99999;
`;

