import React, { useState } from "react";

import Dropdown from "../../../../common/Dropdown/Dropdown";
import {
  DropdownActionButton,
  ActionWrapper,
  DropdownSection,
  ExportPDFSection,
  DownloadBtn,
} from "../MainResultsSection.styles";
import { fetchDashboardExportRequest } from "../../../../../../serverApi";
import ConfirmationModal from "../../../../common/ConfirmationModal/ConfirmationModal";

const MainResultsAction = ({
  userDetails,
  updateDropdown,
  handleChangeData,
  setLoadingStatus,
  data,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState({
    isOpen: false,
    url: "",
  });

  const handleExport = async () => {
    setLoadingStatus(true);
    setShowConfirmationModal({
      isOpen: true,
      url: await fetchDashboardExportRequest(updateDropdown.value.ReportId),
    });
    setLoadingStatus(false);
  };

  return (
    <ActionWrapper>
      <DropdownSection>
        <Dropdown option={{ ...updateDropdown }} data={userDetails.Reports} />
        <DropdownActionButton onClick={handleChangeData}>
          Consulter
        </DropdownActionButton>
      </DropdownSection>
      {data.length > 0 ? (
        <ExportPDFSection>
          <DownloadBtn onClick={handleExport}>
            <p>{updateDropdown.value.ReportName}</p>
            <img
              src={
                "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/pdf_logo.svg"
              }
              alt={"PDF logo"}
            />
          </DownloadBtn>
        </ExportPDFSection>
      ) : (
        <ExportPDFSection isDisabled>
          <DownloadBtn onClick={handleExport} isDisabled>
            <p>{updateDropdown.value.ReportName}</p>
            <img
              src={
                "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/pdf_logo.svg"
              }
              alt={"PDF logo"}
            />
          </DownloadBtn>
        </ExportPDFSection>
      )}
      {showConfirmationModal.isOpen && (
        <ConfirmationModal
          state={{
            value: showConfirmationModal,
            update: setShowConfirmationModal,
          }}
        />
      )}
    </ActionWrapper>
  );
};

export default MainResultsAction;
