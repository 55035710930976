import styled from "styled-components";

export const Img = styled.img`
   width:300px;
    height:150px;
   float:left;
   margin: 15px;
`

export const TextHolder = styled.div`
   margin-top: 15px;
   margin-left:330px;

`
export const P = styled.p`
   margin: 0;
   padding-bottom: 20px;

   &:nth-child(n+5) {
      margin-left: 25px;
   }
   &:nth-last-child(1){
      margin-left:0;
   }
`
export const ShowHideBtn = styled.button`
   color: green;
   background-color: transparent;
   border: none;
   height: 20px;
   font-size: 13px;
   cursor: pointer;
`