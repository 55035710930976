import React, { useEffect } from "react";
import { UserHeadContainer, UserName, ButtonUserLog } from "./Header.styles";
import { fetchUserDetailsRequest, resetToken } from "../../../serverApi";


const UserHead = ({ fullname, getUserDetails, setAuthStatus, setBarChart, setUserDetails }) => {

    useEffect(() => {
        getUserDetails();
    }, []);

    const handleLogout = () => {
        resetToken();
        setAuthStatus(false);
        setBarChart({});
        setUserDetails({});
    };

    return (
        <UserHeadContainer>
            {fullname && <UserName>{fullname}</UserName>}
            <ButtonUserLog to="/login" onClick={handleLogout}>Déconnexion</ButtonUserLog>
        </UserHeadContainer>
    )
};

export default UserHead;