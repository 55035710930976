import styled from "styled-components";

export const Button = styled.a`
    font:15pt Helvetica Regular, Arial, Myriad Pro;
    color:#272727;
    border-radius: 2rem;
    border:0.05rem solid #272727;
    background-color:transparent;
    outline:none;
    text-decoration:none;
    margin:1rem;
    padding:0.5rem 2rem 0.8rem 2rem;
    cursor:pointer;

    &:hover {
        color:#009a74;
        border-color:#00785a;
    }

    &:active {
        color:transparent;
    }
`

export const Hr = styled.hr`
    width:90%;
    margin:0.85rem 0;
    border-top: 0.05rem solid #d4d4d4;
`