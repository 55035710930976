import React from "react";
import { CategoryHolder, CategoryHeader } from "./SubSection.styles";

const Category = ({ imgArray, title, children, width, widthFixedSize }) =>
    <CategoryHolder width={width} widthFixedSize={widthFixedSize} >
        <CategoryHeader>
            {imgArray.map((img, index) => <img key={index} className="titleImg" src={img} alt="Title" />)}
            {title}
        </CategoryHeader>
        {children}
    </CategoryHolder>

export default Category;