import styled from "styled-components";

export const DropdownActionButton = styled.button`
  width: 100px;
  height: 35px;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  background-color: #fff;
  color: #272727;
  border: 1px solid #272727;
  margin-top: 6px;
  margin-left: 15px;
`;

export const DownloadBtn = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "inherit")};
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DropdownSection = styled.div`
  display: flex;
  align-items: center;
`;

export const ExportPDFSection = styled.div`
  display: flex;
  align-items: center;
  p {
    color: #272727;
    font-size: 16px;
    margin-right: 10px;
  }

  img {
    height: 32px;
  }
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};
`;

export const MainResultsSubSectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

export const ResultSection = styled.div`
  border: 1px solid #009a74;
  box-shadow: 0px 0px 5px #00000030;
  border-radius: 3px;
  height: 400px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => (width ? width + "%" : "auto")};

  @media only screen and (max-width: 1220px) {
    width: ${({ isTaux }) => (isTaux ? "44%" : "41%")};
  }
`;

export const SectionTitle = styled.p`
  color: #1d1d1d;
  font-size: 18px;
  font-weight: bold;
`;

export const MainOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #1d1d1d;
    font-size: 16px;
    max-width: ${({ isTaux }) => (isTaux ? "113px" : "auto")};
    margin-left: ${({ isTaux }) => (isTaux ? "12px" : "0px")};
  }
`;

export const ActualYearText = styled.span`
  font-size: 44px;
  font-weight: bold;
  color: ${({ isOk }) =>
    isOk === undefined ? "#1D1D1D" : isOk ? "#009A74" : "#E15D5D"};
  display: flex;
  margin-left: ${({ isTaux }) => (isTaux ? "12px" : "0px")};

  > img {
    height: 18px;
    margin-top: 6px;
  }
`;

export const LastYearText = styled.p`
  color: #1d1d1d;
  font-size: 14px;
  margin-top: 4px;
`;

export const SmallOptions = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: ${({ isTaux }) => (isTaux ? "40px" : "0px")};

  hr {
    height: 80px;
    border: none;
    border-left: 1px solid #b9b9b9;
    margin: 0 ${({ isTaux }) => (isTaux ? "18px" : "21px")};
  }
`;

export const MainOptionText = styled.p``;
export const SubOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #1d1d1d;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 4px;
  }
`;

export const SubOptionActualYear = styled.span`
  font-size: 26px;
  font-weight: bold;
  color: ${({ isOk }) =>
    isOk === undefined ? "#1D1D1D" : isOk ? "#009A74" : "#E15D5D"};
`;

export const SubOptionLastYear = styled.span`
  color: #1d1d1d;
  font-size: 14px;
`;

export const ComparisonSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ComparisonText = styled.p`
  margin: 0;
  max-width: 70%;
`;

export const SectionBody = styled.div`
  @media only screen and (max-width: 1110px) {
    max-width: ${({ isTaux }) => (isTaux ? "113px" : "auto")};
  }
`;

export const PercWrapper = styled.div`
  margin: 10px 0;
`;

export const CmpLine = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;

  p {
    margin: 0 0 0 5px;
  }
`;

export const CoverDiv = styled.div`
  background-color: ${({ actual }) => (actual ? "#009A74" : "#C2C2C2")};
  width: ${({ width }) => width + "px"};
  height: 100%;
`;

export const LineDiv = styled.div`
  width: 100px;
  height: 20px;
  background-color: #f6f6f6;
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0px;
  }
`;

export const ItemLegend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  div {
    width: 12px;
    height: 12px;
    background-color: #c2c2c2;
    margin: 0 10px;
  }

  &:first-child {
    div {
      background-color: #009a74;
    }
  }
`;
