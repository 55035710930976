import React, { useState } from "react";

import Section from "../Main/Home/Section/Section";
import {
  Select,
  DownloadButton,
  ArrowIcon,
  DropdownHeader,
  TextOption,
} from "./SimpleSelectSection.style";
import { fetchReportId } from "../../serverApi";

const SimpleSelectSection = (props) => {
  const {
    title,
    data,
    selectedValue,
    selectedName,
    defaultOption,
    buttonName,
    selectedID,
    type,
  } = props;

  const [optionSelected, setOptionSelected] = useState("");
  const [IdSelected, setIdSelected] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedOptionName, setSelectedOptionName] = useState();

  const changeOptionSelected = (value, option, name) => {
    setOptionSelected(value);
    setIdSelected(parseInt(option));
    setShowDropDown(false);
    setSelectedOptionName(name);
  };

  const onDownloadClick = (id, type) => {
    const data = { id: id, type: type };
    fetchReportId(data);
  };

  return (
    <Section title={title} small center>
      <Select>
        <DropdownHeader onClick={() => setShowDropDown(!showDropDown)}>
          <TextOption>{selectedOptionName || defaultOption}</TextOption>{" "}
          <ArrowIcon shouldRotate={showDropDown}>
            <img
              src={
                "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/arrow_small.svg"
              }
            />
          </ArrowIcon>
        </DropdownHeader>
        {showDropDown && data.length > 0 && (
          <ul>
            {data &&
              data.map(
                (rapport, index) =>
                  rapport[selectedName] !== "" && (
                    <li
                      key={rapport[selectedID]}
                      onClick={() =>
                        changeOptionSelected(
                          rapport[selectedValue],
                          rapport[selectedID],
                          rapport[selectedName]
                        )
                      }
                    >
                      {rapport[selectedName]}
                    </li>
                  )
              )}
          </ul>
        )}
      </Select>
      {optionSelected === "" ? (
        <DownloadButton disabled={true}>{buttonName}</DownloadButton>
      ) : (
        <DownloadButton
          attrID={IdSelected}
          href={optionSelected}
          onClick={() => onDownloadClick(IdSelected, type)}
          target="_blank"
          download
        >
          {buttonName}
        </DownloadButton>
      )}
    </Section>
  );
};

export default SimpleSelectSection;
