import styled from "styled-components";

export const BarChartWrapper = styled.div`
    display:flex;
    flex-direction:column;
    border: 1px solid #009A74;
    justify-content:center;
    align-items:center;

    p {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
    i {
        margin-bottom: 35px;
    }
    .tooltip{
        display:none;
    }
`;