import React from 'react';
import { MainStyled } from "./Main.styles";
import LoginContainer from "./Login/component/Login.container";
import { LoginTitle } from "./Login/component/Login.styles";

const MainLogin = () =>
    <MainStyled center>
        <LoginTitle>Connectez-vous à vos résultats de l'enquête Calypso 2024</LoginTitle>
        <LoginContainer />
    </MainStyled>

export default MainLogin;
