import styled from "styled-components";

export const SMALL_WIDTH = "small";
export const MEDIUM_WIDTH = "medium";
export const LARGE_WIDTH = "large";

export const CategoryHolder = styled.div`
    margin: 2rem;
    font:14pt Helvetica Regular, Arial, Myriad Pro;
    color:#272727;
    ${({ width }) => width && `width:${width};`}
    
    @media only screen and (max-width: 1650px) {
        font-size:13pt;
        margin:1rem;
        ${({ widthFixedSize }) => {
        switch (widthFixedSize) {
            case SMALL_WIDTH: return "width:20vw;";
            case MEDIUM_WIDTH: return "width:40vw;";
            case LARGE_WIDTH: return "width:60vw;";
            default: return "";
        }
    }};
    }

    @media only screen and (max-width: 1245px) {
        font-size:10pt;
        margin:0.5rem;
        ${({ widthFixedSize }) => {
        switch (widthFixedSize) {
            case SMALL_WIDTH: return "width:15rem;";
            case MEDIUM_WIDTH: return "width:28rem;";
            case LARGE_WIDTH: return "width:35rem;";
            default: return "";
        }
    }};
    }

    @media only screen and (max-width: 1024px) {
        font-size:9pt;
    }
`

export const CategoryHeader = styled.div`
    display:flex;
    align-items:flex-end;
    border-bottom:0.1rem solid #d4d4d4;
    padding-bottom:0.5rem;
    font:16pt Helvetica Regular, Arial, Myriad Pro;
    color:#272727;
    font-weight:bold;
    width:inherit;

    .titleImg {
        margin-right:0.5rem;
        margin-bottom: 5px;
        margin-left: 40px;
    }

    @media only screen and (max-width: 1250px) {
        .titleImg {
            margin-left: 0;
        }
    }

    @media only screen and (max-width: 1024px) {
        font-size:13pt;
    }
`
export const SubSectionsHolder = styled.div`
    display:flex;
    flex-direction:row;
    align-items:baseline;
`