import { asyncActionHelper } from "../../../utils";

export const GET_USER_DETAILS = "@@/GET_USER_DETAILS";
export const USER_DETAILS_ACTION_GROUP = asyncActionHelper("@@/USER_DETAILS_ACTION_GROUP");
export const LOGOUT = "@@/LOGOUT";

export const SET_USER_DETAILS = "@@/SET_USER_DETAILS";

export const getUserDetails = () => ({
  type: GET_USER_DETAILS,
});

export const setUserDetails = (data) => ({
  type: SET_USER_DETAILS,
  payload: data
})

export const logout = () => ({
  type: LOGOUT,
});
