import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getRoutePathWithPrefix } from "../../utils";

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
    return (
        <Route {...rest} render={(props) => {
            return (
                <>
                    { isAuthenticated ? <Component {...props} /> : <Redirect to={getRoutePathWithPrefix('login')} />}
                </>
            )
        }} />
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.login.authStatus.isAuthenticated
})

export default connect(mapStateToProps, null)(PrivateRoute);