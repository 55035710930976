import React, { useEffect, useState } from "react";
import Section from "../../Section/Section";
import MainResultsAction from "./Actions/MainResultsActions";
import BarChartContainer from "./BarChart/BarChartContainer";
import ResultsData from "./ResultsData/ResultsData";

const MainResultsSection = ({
  userDetails,
  barChart,
  getMainResultsData,
  sectionData,
  setLoadingStatus,
}) => {
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedOptionAux, setSelectedOptionAux] = useState({});
  let totalRespondents = barChart.length >0 ? barChart.reduce((acc, currentVal) => acc + currentVal.NrRespondents, 0): 0;
  let hasMoreThan10Respondends = totalRespondents > 10;
  useEffect(() => {
    if (
      (Object.keys(barChart).length > 0 ||
        Object.keys(userDetails).length > 0) &&
      Object.keys(selectedOption).length === 0
    ) {
      setSelectedOption(userDetails.Reports[0]);
      setSelectedOptionAux(userDetails.Reports[0]);
      getMainResultsData(userDetails.Reports[0].ReportId);
    }
  }, [userDetails, selectedOption]);
 
  const handleChangeData = () => {
    setSelectedOption(selectedOptionAux);
    getMainResultsData(selectedOptionAux.ReportId);
  };

  return (
    <Section title={"Vos principaux resultats"}>
      <MainResultsAction
        data={sectionData}
        setLoadingStatus={setLoadingStatus}
        userDetails={userDetails}
        handleChangeData={handleChangeData}
        updateDropdown={{
          value: selectedOption,
          tmpValue: selectedOptionAux,
          update: setSelectedOptionAux,
        }}
      />
      {sectionData && <ResultsData data={sectionData} />}
      {hasMoreThan10Respondends && barChart && <BarChartContainer data={barChart} />}
    </Section>
  );
};

export default MainResultsSection;
