import styled from "styled-components";

export const Img = styled.img`
    width:2rem;
`

export const ContactWrapper = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 130px;

    span {
        color: #272727;
    }

    span:first-child {
        font-size: 20px;
    }
`

export const ContactText = styled.span`
    font-size: 14px;
`

export const ContactEmail = styled.a`
    display:flex;
    margin-right:5rem;
    margin-top:0.5rem;
    color:#272727;
    text-decoration: none;
    cursor: pointer;
    align-items: center;

    p {
        margin-left: 10px;
    }
`