import React from "react";
import Section from "../Section/Section";
import { Button, Hr } from "./RapportsSection.styles";
import { fetchReportId } from '../../../../serverApi';
import { basePosterPath, baseRaportPath } from "../../../../utils";

const RapportsSection = () => {
  const onDownloadClick = (id, type) => {
    const data = { id: id, type: type };
    fetchReportId(data);
  };

    return (
        <Section title="Rapports Nationaux" small center>
            Rapport National 2024
            <Button href={`${baseRaportPath}`}
                onClick={() => onDownloadClick(0, 1)}
                target="_blank" download>Consulter</Button>
            <Hr />
            Poster National 2024
            <Button href={`${basePosterPath}`}
                onClick={() => onDownloadClick(0, 3)}
                target="_blank" download>Consulter</Button>
        </Section >
    )
}
export default RapportsSection;
