import React from "react";
import { BarChart, BARCHART_DIRECTION, BARCHART_LABEL_POSITION } from '@toluna-ui-toolkit/barchart';
import { BarChartWrapper } from "./BarChart.styles";

const BarChartContainer = ({ data }) => {
    
    if (!data || !data.length > 0) return <p style={{ textAlign: 'center', border: 'solid 1px #009a74', padding: '15px', fontWeight: '600' }}>No data available.</p>  

    const sortedData = [];
    if (data) {
        data.map(el => {
            if (el.Label === 'Autres') {
                el.Order = data.length - 2;
            }
            if (el.Label === 'NSP') {
                el.Order = data.length - 1;
            }

            const newSortedData = {
                key: el.Order,
                name: el.Label,
                value: el.Perc,
                color: '#AF0E2D',
                textColor: '#000000',
            };

            sortedData.push(newSortedData);

        })
        sortedData.sort((a, b) => a.key > b.key ? 1 : ((b.key > a.key) ? -1 : 0));
    }
    sortedData.reverse();

    return (
        <BarChartWrapper>
            <p>Axes d'améliorations prioritaires</p>
            <i>Attention base faible, résultats à prendre avec prudence</i>
            <BarChart
                width={250}
                height={450}
                marginWidth={280}
                translateX={230}
                data={sortedData}
                direction={BARCHART_DIRECTION.HORIZONTAL}
                labelPosition={BARCHART_LABEL_POSITION.END}
                labelFormatter={e => e + '%'}
                withNames={true}
                styling={{
                    fontWeight: 600,
                    barNamesFontSize: 14,
                    barFontSize: 14,
                    barNamesSecondaryAxisOffset: -10,
                    barLabelMainAxisOffset: 40,
                    barWidth: 25,
                    barNamesMainAxisOffset: 10,
                    barNamesSecondaryAxisOffset: -12,
                    barNamesWidth: 200,
                }}
            />
        </BarChartWrapper>
    );
}
export default BarChartContainer;
