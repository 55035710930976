import React, { useEffect, useState } from "react";
import { Img, ExportWrapper } from "./ExportSection.styles";
import { Holder } from "../../../common/common.styles";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { fetchExportUserLogRequest } from "../../../../../serverApi";

const ExportSection = ({ roleName, setLoadingStatus }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState({
    isOpen: false,
    url: "",
  });

  const handleExport = async () => {
    setLoadingStatus(true);
    setShowConfirmationModal({
      isOpen: true,
      url: await fetchExportUserLogRequest(),
    });
    setLoadingStatus(false);
  };

  if (roleName && roleName === "Admin") {
    return (
      <Holder>
        <ExportWrapper onClick={handleExport}>
          <span>Télécharger le fichier journal</span>
          <Img
            src={
              "https://d11t8mrhcw6vp8.cloudfront.net/assets/OCP/images/excel_logo.svg"
            }
            alt="excel logo"
          />
        </ExportWrapper>
        {showConfirmationModal.isOpen && (
          <ConfirmationModal
            state={{
              value: showConfirmationModal,
              update: setShowConfirmationModal,
            }}
          />
        )}
      </Holder>
    );
  }
  return null;
};

export default ExportSection;
