import styled from "styled-components";
import { NavLink } from "react-router-dom"

export const HeaderStyled = styled.header`
    width:94vw;
    height:69px;
    background-color:#009a74;
    color:white;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:1vh 3vw;
    cursor:default;
`

export const ImgStyled = styled.img`
    height:2rem;
`

export const UserHeadContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
`

export const UserName = styled.div`
    color:#ffffff;
    font:18pt Helvetica Regular, Arial, Myriad Pro;
    margin:0.3rem;

    @media only screen and (max-width: 1650px) {
        font-size:16pt;
    }

    @media only screen and (max-width: 1024px) {
        font-size:15pt;
    }
`

export const ButtonUserLog = styled(NavLink)`
    width:7rem;
    padding:0.3rem;
    border:none;
    background-color:#00785a;
    border-radius:0.2rem;
    font-family:Helvetica Regular, Arial, Myriad Pro;
    font-size:12pt;
    color:#ffffff;
    outline:none;
    cursor:pointer;
    text-align:center;
    text-decoration:none;

    &:active{
        width:6.8rem;
        color:transparent;
    }
`