import React from 'react';
import Header from "../components/Header/component/Header";
import MainHome from "../components/Main/MainHome";

const HomePage = ({ username }) =>
  <React.Fragment>
    <Header username={username} />
    <MainHome />
  </React.Fragment>


export default HomePage;
