import styled from "styled-components";

export const Text = styled.p`
    margin-bottom:0.1rem;
    &.secondText{
        margin-top:0.1rem;
    }
`

export const TitleTable = styled.p`
    margin-top: 40px;
`

export const Table = styled.table`
    font-family:inherit;
    font-size:90%;
    color:#272727;
    border-collapse:collapse;

    thead {
        background-color: #009a74;
    }

    th,td {
        text-align:center;
        border: none;
        padding:0.3rem;
    }

    tr{
        height: 50px;
    }

    tbody > tr:first-child {
        border-bottom:0.05rem solid #B4B5BE;
    }
    
    th{
        background-color:#009a74;
        color:white;
        height: 35px;
    }

    .break {
        background:none;
        border:none;
        width:5%;
        padding: 0;

        > div {
            height: 46px;
            width: 1px;
            margin-left: 20px;
            background-color: #fff;
        }
    }

`

export const TableDescription = styled.p`
    font-family:inherit;
    font-size:90%;
    font-style:italic;
    color: #B4B5BE;
    margin: 0.1rem;
    ${({ width }) => width && `width: ${width};`}

    > span {
        background-color: #009A74;
        color: #fff;
        font-style: normal;
    }
`